import {
	deleteDataAPI,
	patchDataAPI,
	postDataAPI,
} from "../../utils/fetchData";
import { DeleteData, EditData, GLOBALTYPES } from "./globalTypes";
import { createNotify, removeNotify } from "./notifyAction";
import { POST_TYPES } from "./postAction";

export const createComment =
	({ post, newComment, auth, socket }) =>
	async (dispatch) => {
		const newPost = { ...post, comments: [...post.comments, newComment] };

		dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost });

		try {
			const data = {
				...newComment,
				postId: post.id,
				postUserId: post.user.id,
			};
			const res = await postDataAPI("comment", data, auth.token);

			const newData = { ...res.data.newComment, user: auth.user };
			const newPost = { ...post, comments: [...post.comments, newData] };
			dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost });

			// todo socket
			socket.emit("createComment", newPost);

			// todo notification
			const msg = {
				id: res.data.newComment.id,
				text: newComment.reply
					? "mentioned you in a comment."
					: "commented on your post.",
				recipients: newComment.reply ? [newComment.tag.id] : [post.user.id],
				url: `/post/${post.id}`,
				content: newComment.reply ? newComment.content : post.content,
				image: post.images[0].url,
			};

			dispatch(createNotify({ msg, auth, socket }));
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

export const updateComment =
	({ comment, post, content, auth }) =>
	async (dispatch) => {
		const newComments = EditData(post.comments, comment.id, {
			...comment,
			content,
		});
		const newPost = { ...post, comments: newComments };

		dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost });

		try {
			await patchDataAPI(`comment/${comment.id}`, { content }, auth.token);
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

export const likeComment =
	({ comment, post, auth }) =>
	async (dispatch) => {
		const newComment = { ...comment, likes: [...comment.likes, auth.user] };
		const newComments = EditData(post.comments, comment.id, newComment);
		const newPost = { ...post, comments: newComments };

		dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost });
		try {
			await patchDataAPI(`comment/${comment.id}/like`, null, auth.token);
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

export const unLikeComment =
	({ comment, post, auth }) =>
	async (dispatch) => {
		const newComment = {
			...comment,
			likes: DeleteData(comment.likes, auth.user.id),
		};
		const newComments = EditData(post.comments, comment.id, newComment);
		const newPost = { ...post, comments: newComments };

		dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost });
		try {
			await patchDataAPI(`comment/${comment.id}/unlike`, null, auth.token);
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

export const deleteComment =
	({ post, comment, auth, socket }) =>
	async (dispatch) => {
		const deleteArr = [
			...post.comments.filter((cm) => cm.reply === comment.id),
			comment,
		];

		const newPost = {
			...post,
			comments: post.comments.filter(
				(cm) => !deleteArr.find((da) => cm.id === da.id)
			),
		};

		dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost });

		socket.emit("deleteComment", newPost);

		try {
			deleteArr.forEach((item) => {
				deleteDataAPI(`comment/${item.id}`, auth.token);

				// todo notification
				const msg = {
					id: item.id,
					text: item.reply
						? "mentioned you in a comment."
						: "commented on your post.",
					recipients: item.reply ? [item.tag.id] : [post.user.id],
					url: `/post/${post.id}`,
				};

				dispatch(removeNotify({ msg, auth, socket }));
			});
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};
