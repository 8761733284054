import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./communitystyles.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ImageCropper from "./imagecroppopup";

const Community = () => {
  const { theme } = useSelector((state) => state);
  const communitiesArray = [1, 2, 3, 4, 1];
  const mostActiveCommunitiesArray = [1, 2, 3, 4, 5, 6, 7, 8];
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [showProfilePhotoModal, setShowProfilePhotoModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [croppedImageCoverPhoto, setCroppedImageCoverPhoto] =
    useState(undefined);
  const [croppedImageProfilePhoto, setCroppedImageProfilePhoto] =
    useState(undefined);
  const [getUpload, setGetUpload] = useState();
  const [size, setSize] = useState("");
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [imageToCropCoverPhoto, setImageToCropCoverPhoto] = useState(undefined);
  const [imageToCropProfilePhoto, setImageToCropProfilePhoto] =
    useState(undefined);

  const onUploadFile = (event) => {
    let sizeInBytes = event.target.files[0].size;
    var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
    setSize(sizeInMB + "MB");

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const image = reader.result;
        console.log(image);
        setGetUpload(image);
        setImageToCrop(image);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const onUploadFileCoverPhoto = (event) => {
    let sizeInBytes = event.target.files[0].size;
    var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
    setSize(sizeInMB + "MB");

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const image = reader.result;
        // console.log(image);
        // setGetUpload(image);
        setImageToCropCoverPhoto(image);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const onUploadFileProfilePhoto = (event) => {
    let sizeInBytes = event.target.files[0].size;
    var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
    setSize(sizeInMB + "MB");

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const image = reader.result;
        // console.log(image);
        // setGetUpload(image);
        setImageToCropProfilePhoto(image);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };
  const handleClose2 = () => {
    setShow2(false);
  };
  const handleOpen2 = () => {
    setShow2(true);
    setShowJoinModal(false);
  };

  const handleClose3 = () => {
    setShow3(false);
  };

  const handleCloseJoinModal = () => {
    setShowJoinModal(false);
  };
  const handleOpenjoinModal = () => {
    setShowJoinModal(true);
  };

  const handleCloseProfilePhotoModal = () => {
    setShowProfilePhotoModal(false);
    setShowJoinModal(true);
  };

  const handleOpenprofilePhotoModal = () => {
    setShowProfilePhotoModal(true);
    setShowJoinModal(false);
  };

  const FirstTab = (props) => {
    return (
      <div>
        <Button
          onClick={buttonClick}
          style={{
            backgroundColor: "#3CCE8F",
            border: "none",
            borderRadius: "4px",
            marginTop: "-6px",
          }}
        >
          Create Community
        </Button>
      </div>
    );
  };
  const buttonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("Button clicked");
    setShow3(true);
  };
  const inputFile = useRef(null);
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };
  const inputFileCoverPhoto = useRef(null);
  const onButtonClickCoverPhoto = () => {
    // `current` points to the mounted file input element
    inputFileCoverPhoto.current.click();
  };

  const inputFileProfilePhoto = useRef(null);
  const onButtonClickProfilePhoto = () => {
    // `current` points to the mounted file input element
    inputFileProfilePhoto.current.click();
  };
  return (
    <>
      <div style={{ padding: "20px 60px 20px 120px" }}>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          // className="mb-3"
          style={{
            backgroundColor: "#fff",
            borderRadius: "15px",
            height: "55px",
          }}
        >
          <Tab eventKey="home" title="Communities">
            <div className="">
              <div className="row pt-3">
                <div
                  className="col-xl-8 col-lg-12 col-md-12 col-sm-12 card"
                  style={{
                    marginRight: "75px",
                    border: "none",
                    borderRadiusTop: "15px",
                    marginLeft: "10px",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F4F7F9",
                      padding: "15px 15px",
                      marginLeft: "-12px",
                      marginRight: "-12px",
                      fontWeight: "600",
                      fontSize: "16px",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  >
                    Communities you may like to join
                  </div>
                  <div>
                    <div className="row">
                      {communitiesArray.map((e) => {
                        return (
                          <div
                            className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                            style={{ padding: "10px 10px 10px 10px" }}
                          >
                            <div class="uc-community-li-main">
                              {" "}
                              <div class="uc-comm-like-imgs">
                                <img
                                  onerror="communityBannerImgError(this);"
                                  src="https://www.ulektz.com/assets/img/home/community-common-background.png"
                                  alt="img"
                                />
                              </div>
                              <div class="uc-comm-userimg">
                                {" "}
                                <img
                                  src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/28215421.png"
                                  alt=""
                                  onerror="profileImgError(this);"
                                  class="uc-comm-user-img"
                                  onclick="communityChangeNew(5421)"
                                />{" "}
                              </div>
                              <div class="uc-right-top-wrap">
                                <a
                                  href="javascript:void(0)"
                                  class="uc-option-btn"
                                >
                                  {" "}
                                  <img
                                    src="https://www.ulektz.com/assets/img/home/options.svg"
                                    alt="icon"
                                  />{" "}
                                </a>{" "}
                                <a
                                  href="javascript:void(0)"
                                  class="uc-rb-btn"
                                  //   onclick="openReportCommunityPopup(5421,this)"
                                >
                                  <span
                                    class="uc-rc-pop-span"
                                    style={{ position: "absolute" }}
                                  >
                                    {/* <img src="https://www.ulektz.com/assets/img/home/Report_post.svg" />{" "}
                                    Report Community */}
                                  </span>
                                </a>{" "}
                              </div>{" "}
                              <div class="uc-community-li-con">
                                {" "}
                                <div>
                                  <h4 onclick="communityChangeNew(5421)">
                                    Nuclear Physics
                                  </h4>{" "}
                                  <div class="uc-pro-location">
                                    {" "}
                                    <p onclick="communityChangeNew(5421)">
                                      {" "}
                                      No. 100, Lake View Estate, Kundrathur Main
                                      Road,Porur, Chennai - 600 116{" "}
                                    </p>{" "}
                                    <div
                                      class="public-text"
                                      onclick="communityChangeNew(5421)"
                                    >
                                      <span class="uc-star-count">
                                        <a
                                          href="javascript:void(0)"
                                          class="cmn-btn uc-normal-btn"
                                        >
                                          <img src="https://www.ulektz.com/assets/img/profile/Public_icon.svg" />{" "}
                                          Public
                                        </a>
                                        <a
                                          href="javascript:void(0)"
                                          class="cmn-btn uc-blue-btn type"
                                        >
                                          <span class="">Skill</span>
                                        </a>
                                      </span>
                                    </div>{" "}
                                    <div class="uc-pv-wrap-comb-mem">
                                      {" "}
                                      <div class="uc-pv-wrap">

                                      </div>{" "}
                                      <p
                                        // onclick="communityChangeNew(5421)"
                                        // class="pb-2"
                                        style={{ marginBottom: "0rem" }}
                                      >
                                        <span class="">23</span>&nbsp;Members
                                      </p>{" "}
                                    </div>{" "}
                                  </div>
                                </div>{" "}
                                <div
                                  onClick={handleOpenjoinModal}

                                  class="cmn-btn uc-join-btn uc-com-join-toggle"
                                >
                                  +Join
                                </div>{" "}
                              </div>{" "}
                            </div>

                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-3 col-lg-12 col-md-12 col-sm-12 card"
                  style={{
                    maxHeight: "calc(100vh - 235px)",
                    border: "none",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F4F7F9",
                      padding: "15px 15px",
                      marginLeft: "-12px",
                      marginRight: "-12px",
                      fontWeight: "600",
                      fontSize: "16px",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  >
                    Most active communities
                  </div>
                  <div
                    style={{
                      overflowY: "auto",
                      maxHeight: "calc(100vh - 235px)",
                      overflowX: "hidden",
                    }}
                  >
                    {" "}
                    {mostActiveCommunitiesArray.map((e) => {
                      return (
                        <>
                          <div className="row" style={{ padding: "5px 5px" }}>
                            <div
                              className="col-3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <span onclick="communityChangeNew(132)">
                                <img
                                  src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/642847167.png"
                                  alt=""
                                  width="45px"
                                  heght="45px"
                                  onerror="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
                                />
                              </span>
                            </div>
                            <div
                              className="col-9"
                              style={{ paddingLeft: "0px" }}
                            >
                              <span
                                class="uc-community-cnt"
                                onclick="communityChangeNew(132)"
                              >
                                <h5>
                                  Alagappa University Directorate of Distance
                                  Education
                                </h5>
                                <div className="row">
                                  <div className="col-7">
                                    <p class="uc-communityLike-in">
                                      <span>
                                        Public Group
                                        <br />
                                        41866 members
                                      </span>
                                    </p>
                                  </div>
                                  <div
                                    className="col-5"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <Button
                                      class="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
                                      onClick={handleOpenjoinModal}
                                      style={{
                                        background: "#E23F1C",
                                        border: "none",
                                      }}
                                    >
                                      + Join
                                    </Button>
                                  </div>
                                </div>
                              </span>
                            </div>
                            <a
                              data-id="132"
                              href="javascript:void(0);"
                              class="uc-com-join-prof-toggle"
                            ></a>
                          </div>
                          <hr
                            style={{ marginTop: "0px", marginBottom: "0px" }}
                          />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="profile" title="My Communities">
            <div className="">
              <div className="row" style={{ paddingTop: "15px" }}>
                <div
                  className="col-xl-8 col-lg-12 col-md-12 col-sm-12 card"
                  style={{
                    marginRight: "75px",
                    marginLeft: "10px",
                    border: "none",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    className="row"
                    style={{
                      backgroundColor: "#F4F7F9",
                      padding: "15px 15px",
                      marginLeft: "-12px",
                      marginRight: "-12px",
                      fontWeight: "600",
                      fontSize: "16px",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  >
                    <div
                      className="col-8"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Communities you've joined
                    </div>
                    <div
                      className="col-1"
                      style={{
                        width: "auto",
                        paddingRight: "0px",
                        paddingLeft: "0px",
                      }}
                    >
                      <button
                        class="ripple-button uc-extra-options-button"
                        id="uc-add-clsJRS"
                        style={{
                          background: "#fff",
                          width: "35px",
                          height: "35px",
                          borderRadius: "4px",
                          border: "1px solid #ddd",
                        }}
                      >
                        <img
                          class="uc-extra-options-img"
                          src="https://www.ulektz.com/assets/img/header/sort_icon.svg"
                          alt=""
                        />
                      </button>
                    </div>
                    <div className="col-3">
                      <input
                        type="text"
                        placeholder="Search"
                        style={{
                          padding: "4px 4px 4px 20px",
                          border: "1px solid #ddd",
                          borderRadius: "4px",
                          backgroundImage: "url(./images/search.svg)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "18px",
                          backgroundPosition: "left",
                        }}
                      ></input>
                    </div>
                  </div>

                  <div>
                    <div
                      className="row"
                      style={{ padding: "10px 10px", cursor: "pointer" }}
                      onClick={() => {
                        window.location.href = "/communitykrishigap";
                      }}
                    >
                      {communitiesArray.map((e) => {
                        return (
                          <div
                            className="col-12"
                            style={{ marginBottom: "10px" }}
                          >
                            <div
                              className="row"
                              style={{
                                padding: "10px 10px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                              }}
                            >
                              <div
                                className="col-1"
                                style={{
                                  paddingLeft: "0px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src="./images/krishigaplogo.png"
                                  style={{
                                    borderRadius: "50%",
                                    height: "70px",
                                  }}
                                ></img>
                              </div>
                              <div
                                className="col-10"
                                style={{ paddingLeft: "20px" }}
                              >
                                <div
                                  className="row"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="col-10"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Global Food Safety and Sustainability
                                    Standards Platform for Networking, Learning
                                    and Development
                                  </div>
                                  <div
                                    className="col-2"
                                    style={{
                                      fontSize: "12px",
                                      background: "#F96342",
                                      height: "20px",
                                      color: "#fff",
                                      borderRadius: "3px",
                                      width: "70px",
                                      paddingLeft: "4px",
                                      paddingRight: "4px",
                                    }}
                                  >
                                    Moderator
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{
                                    paddingLeft: "0px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="col-7">
                                    <img
                                      src="./images/krishigaplogo.png"
                                      style={{ height: "30px" }}
                                    ></img>
                                    27 members
                                  </div>

                                  <div
                                    class="public-text col-5"
                                    onclick="communityChangeNew(5421)"
                                  >
                                    <span class="uc-star-count">
                                      <a
                                        href="javascript:void(0)"
                                        class="cmn-btn uc-normal-btn"
                                      >
                                        <img src="https://www.ulektz.com/assets/img/profile/Public_icon.svg" />{" "}
                                        Public
                                      </a>
                                      <a
                                        href="javascript:void(0)"
                                        class="cmn-btn uc-blue-btn type"
                                      >
                                        <span class="">Professional</span>
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-1"></div> */}
                              <div
                                className="col-1"
                                style={{
                                  // paddingLeft: "0px",
                                  display: "flex",
                                  alignItems: "center",
                                  // justifyContent:"end"
                                }}
                              >
                                <div class="uc-right-top-wrap">
                                  {" "}
                                  <a
                                    href="javascript:void(0)"
                                    class="uc-option-btn pop-open"
                                  >
                                    {" "}
                                    <img
                                      src="https://www.ulektz.com/assets/img/home/options.svg"
                                      alt="icon"
                                    />{" "}
                                  </a>{" "}
                                  <a
                                    href="javascript:void(0)"
                                    class="uc-rb-btn uc-leave-btn opt-pop"
                                    onclick="removeBlockPopup(5641, 19,this)"
                                  >
                                    <span class="uc-ltc-pop-span d-none">
                                      <img
                                        src="https://www.ulektz.com/assets/img/community/Leave.svg"
                                        alt=""
                                      />
                                      Leave this Community
                                    </span>
                                  </a>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-3 col-lg-4 col-md-12 col-sm-12 card"
                  style={{
                    maxHeight: "450px",
                    border: "none",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F4F7F9",
                      padding: "15px 15px",
                      marginLeft: "-12px",
                      marginRight: "-12px",
                      fontWeight: "600",
                      fontSize: "16px",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  >
                    Communities you may like to join
                  </div>
                  <div
                    style={{
                      overflowY: "auto",

                      maxHeight: "calc(100vh - 335px)",
                      overflowX: "hidden",
                    }}
                  >
                    {" "}
                    {mostActiveCommunitiesArray.map((e) => {
                      return (
                        <>
                          <div className="row">
                            <div
                              className="col-3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <span onclick="communityChangeNew(132)">
                                <img
                                  src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/642847167.png"
                                  alt=""
                                  width="45px"
                                  heght="45px"
                                  onerror="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
                                />
                              </span>
                            </div>
                            <div className="col-9">
                              <span
                                class="uc-community-cnt"
                                onclick="communityChangeNew(132)"
                              >
                                <h5>
                                  Alagappa University Directorate of Distance
                                  Education
                                </h5>
                                <div className="row">
                                  <div className="col-7">
                                    <p class="uc-communityLike-in">
                                      <span>
                                        Public Group
                                        <br />
                                        41866 members
                                      </span>
                                    </p>
                                  </div>
                                  <div
                                    className="col-5"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <span
                                      class="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
                                      onClick={handleOpenjoinModal}
                                    >
                                      + Join
                                    </span>
                                  </div>
                                </div>
                              </span>
                            </div>
                            <a
                              data-id="132"
                              href="javascript:void(0);"
                              class="uc-com-join-prof-toggle"
                            ></a>
                          </div>
                          <hr
                            style={{ marginTop: "0px", marginBottom: "0px" }}
                          />
                        </>
                      );
                    })}
                  </div>
                  <div>
                    <div class="uc-discover-ele-div">
                      <div class="uc-discover-ele-img-div">
                        <img src="https://www.ulektz.com/assets/img/no-content-img/Discover_Community-img-2.svg" />
                      </div>
                      <div class="uc-discover-ele-cnt-div">
                        <h3>Discover more community</h3>
                        <p>
                          Find other trusted communities that share and support
                          your goals.
                        </p>
                        <a
                          href="https://www.ulektz.com/search?searchValue=&amp;tag=community"
                          class="uc-discover-com-btn"
                        >
                          Discover
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="contact" title="Invitations">
            <Tabs
              defaultActiveKey="Invitation Received"
              id="uncontrolled-tab-example"
              style={{
                backgroundColor: "#F4F7F9",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
              className="newtabs"
            >
              <Tab eventKey="Invitation Received" title="Invitation Received">
                {" "}
                <div className="pt-3">
                  <div className="row" style={{}}>
                    <div
                      className="col-xl-8 col-lg-12 col-md-12 col-sm-12 card"
                      style={{
                        marginRight: "75px",
                        marginLeft: "10px",
                        border: "none",
                        borderRadius: "15px",
                      }}
                    >
                      <div
                        className="row"
                        style={{
                          backgroundColor: "#F4F7F9",
                          padding: "15px 15px",
                          marginLeft: "-12px",
                          marginRight: "-12px",
                          fontWeight: "600",
                          fontSize: "16px",
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                        }}
                      >
                        <div
                          className="col-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          Invitations Received
                        </div>

                        <div
                          className="col-1"
                          style={{
                            width: "auto",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            class="ripple-button uc-extra-options-button"
                            id="uc-add-clsJRS"
                            style={{
                              background: "#fff",
                              width: "35px",
                              height: "35px",
                              borderRadius: "4px",
                              border: "1px solid #ddd",
                            }}
                          >
                            <img
                              class="uc-extra-options-img"
                              src="https://www.ulektz.com/assets/img/header/sort_icon.svg"
                              alt=""
                            />
                          </button>
                        </div>

                        <div className="col-3" style={{ textAlign: "end" }}>
                          <input
                            type="text"
                            placeholder="Search"
                            style={{
                              padding: "4px 4px 4px 20px",
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              backgroundImage: "url(./images/search.svg)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "18px",
                              backgroundPosition: "left",
                            }}
                          ></input>
                        </div>
                      </div>
                      {/* <div>
                        <div className="row" style={{ padding: "10px 10px" }}>
                          {communitiesArray.map((e) => {
                            return (
                              <div className="col-12">
                                <div
                                  className="row"
                                  style={{
                                    padding: "10px 10px",
                                    border: "1px solid #000",
                                  }}
                                >
                                  <div className="col-2">
                                    <img
                                      src="./images/krishigaplogo.png"
                                      style={{
                                        borderRadius: "50%",
                                        height: "70px",
                                      }}
                                    ></img>
                                  </div>
                                  <div className="col-9">
                                    <div className="row">
                                      <div className="col-10">
                                        Global Food Safety and Sustainability
                                        Standards Platform for Networking,
                                        Learning and Development
                                      </div>
                                      <div
                                        className="col-2"
                                        style={{
                                          fontSize: "12px",
                                          background: "#FBB3A3",
                                          height: "20px",
                                        }}
                                      >
                                        Moderator
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-7">
                                        <img
                                          src="./images/krishigaplogo.png"
                                          style={{ height: "30px" }}
                                        ></img>
                                        27 members
                                      </div>

                                      <div
                                        class="public-text col-5"
                                        onclick="communityChangeNew(5421)"
                                      >
                                        <span class="uc-star-count">
                                          <a
                                            href="javascript:void(0)"
                                            class="cmn-btn uc-normal-btn"
                                          >
                                            <img src="https://www.ulektz.com/assets/img/profile/Public_icon.svg" />{" "}
                                            Public
                                          </a>
                                          <a
                                            href="javascript:void(0)"
                                            class="cmn-btn uc-blue-btn type"
                                          >
                                            <span class="">Professional</span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-1">
                                    <div class="uc-right-top-wrap">
                                      {" "}
                                      <a
                                        href="javascript:void(0)"
                                        class="uc-option-btn pop-open"
                                      >
                                        {" "}
                                        <img
                                          src="https://www.ulektz.com/assets/img/home/options.svg"
                                          alt="icon"
                                        />{" "}
                                      </a>{" "}
                                      <a
                                        href="javascript:void(0)"
                                        class="uc-rb-btn uc-leave-btn opt-pop"
                                        onclick="removeBlockPopup(5641, 19,this)"
                                      >
                                        <span class="uc-ltc-pop-span d-none">
                                          <img
                                            src="https://www.ulektz.com/assets/img/community/Leave.svg"
                                            alt=""
                                          />
                                          Leave this Community
                                        </span>
                                      </a>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div> */}
                      <div>
                        <div class="uc-discover-ele-div">
                          <div class="uc-discover-ele-img-div">
                            <img src="https://www.ulektz.com/assets/img/no-content-img/Discover_Community-img-2.svg" />
                          </div>
                          <div class="uc-discover-ele-cnt-div">
                            <h3>Discover more community</h3>
                            <p>
                              Find other trusted communities that share and
                              support your goals.
                            </p>
                            <a
                              href="https://www.ulektz.com/search?searchValue=&amp;tag=community"
                              class="uc-discover-com-btn"
                            >
                              Discover
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-lg-12 col-md-12 col-sm-12 card"
                      style={{
                        maxHeight: "calc(100vh - 235px)",
                        border: "none",
                        borderRadius: "15px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#F4F7F9",
                          padding: "15px 15px",
                          marginLeft: "-12px",
                          marginRight: "-12px",
                          fontWeight: "600",
                          fontSize: "16px",
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                        }}
                      >
                        Communities you may like to join
                      </div>
                      <div
                        style={{
                          overflowY: "auto",

                          maxHeight: "calc(100vh - 335px)",
                          overflowX: "hidden",
                        }}
                      >
                        {" "}
                        {mostActiveCommunitiesArray.map((e) => {
                          return (
                            <>
                              <div className="row">
                                <div
                                  className="col-3"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <span onclick="communityChangeNew(132)">
                                    <img
                                      src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/642847167.png"
                                      alt=""
                                      width="45px"
                                      heght="45px"
                                      onerror="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
                                    />
                                  </span>
                                </div>
                                <div className="col-9">
                                  <span
                                    class="uc-community-cnt"
                                    onclick="communityChangeNew(132)"
                                  >
                                    <h5>
                                      Alagappa University Directorate of
                                      Distance Education
                                    </h5>
                                    <div className="row">
                                      <div className="col-7">
                                        <p class="uc-communityLike-in">
                                          <span>
                                            Public Group
                                            <br />
                                            41866 members
                                          </span>
                                        </p>
                                      </div>
                                      <div
                                        className="col-5"
                                        style={{ paddingLeft: "0px" }}
                                      >
                                        <span
                                          class="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
                                          onClick={handleOpenjoinModal}
                                        >
                                          + Join
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                                <a
                                  data-id="132"
                                  href="javascript:void(0);"
                                  class="uc-com-join-prof-toggle"
                                ></a>
                              </div>
                              <hr
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                }}
                              />
                            </>
                          );
                        })}
                      </div>
                      <div>
                        <div class="uc-discover-ele-div">
                          <div class="uc-discover-ele-img-div">
                            <img src="https://www.ulektz.com/assets/img/no-content-img/Discover_Community-img-2.svg" />
                          </div>
                          <div class="uc-discover-ele-cnt-div">
                            <h3>Discover more community</h3>
                            <p>
                              Find other trusted communities that share and
                              support your goals.
                            </p>
                            <a
                              href="https://www.ulektz.com/search?searchValue=&amp;tag=community"
                              class="uc-discover-com-btn"
                            >
                              Discover
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Join Request Sent" title="Join Request Sent">
                {" "}
                <div className="">
                  <div className="row" style={{ paddingTop: "15px" }}>
                    <div
                      className="col-xl-8 col-lg-12 col-md-12 col-sm-12 card"
                      style={{
                        marginRight: "75px",
                        marginLeft: "10px",
                        border: "none",
                        borderRadius: "15px",
                      }}
                    >
                      <div
                        className="row"
                        style={{
                          backgroundColor: "#F4F7F9",
                          padding: "15px 15px",
                          marginLeft: "-12px",
                          marginRight: "-12px",
                          fontWeight: "600",
                          fontSize: "16px",
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                        }}
                      >
                        <div
                          className="col-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          Join Request Sent
                        </div>

                        <div
                          className="col-1"
                          style={{
                            width: "auto",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            class="ripple-button uc-extra-options-button"
                            id="uc-add-clsJRS"
                            style={{
                              background: "#fff",
                              width: "35px",
                              height: "35px",
                              borderRadius: "4px",
                              border: "1px solid #ddd",
                            }}
                          >
                            <img
                              class="uc-extra-options-img"
                              src="https://www.ulektz.com/assets/img/header/sort_icon.svg"
                              alt=""
                            />
                          </button>
                        </div>

                        <div className="col-3" style={{ textAlign: "end" }}>
                          <input
                            type="text"
                            placeholder="Search"
                            style={{
                              padding: "4px 4px 4px 20px",
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              backgroundImage: "url(./images/search.svg)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "18px",
                              backgroundPosition: "left",
                            }}
                          ></input>
                        </div>
                      </div>
                      {/* <div>
                        <div className="row" style={{ padding: "10px 10px" }}>
                          {communitiesArray.map((e) => {
                            return (
                              <div className="col-12">
                                <div
                                  className="row"
                                  style={{
                                    padding: "10px 10px",
                                    border: "1px solid #000",
                                  }}
                                >
                                  <div className="col-2">
                                    <img
                                      src="./images/krishigaplogo.png"
                                      style={{
                                        borderRadius: "50%",
                                        height: "70px",
                                      }}
                                    ></img>
                                  </div>
                                  <div className="col-9">
                                    <div className="row">
                                      <div className="col-10">
                                        Global Food Safety and Sustainability
                                        Standards Platform for Networking,
                                        Learning and Development
                                      </div>
                                      <div
                                        className="col-2"
                                        style={{
                                          fontSize: "12px",
                                          background: "#FBB3A3",
                                          height: "20px",
                                        }}
                                      >
                                        Moderator
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-7">
                                        <img
                                          src="./images/krishigaplogo.png"
                                          style={{ height: "30px" }}
                                        ></img>
                                        27 members
                                      </div>

                                      <div
                                        class="public-text col-5"
                                        onclick="communityChangeNew(5421)"
                                      >
                                        <span class="uc-star-count">
                                          <a
                                            href="javascript:void(0)"
                                            class="cmn-btn uc-normal-btn"
                                          >
                                            <img src="https://www.ulektz.com/assets/img/profile/Public_icon.svg" />{" "}
                                            Public
                                          </a>
                                          <a
                                            href="javascript:void(0)"
                                            class="cmn-btn uc-blue-btn type"
                                          >
                                            <span class="">Professional</span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-1">
                                    <div class="uc-right-top-wrap">
                                      {" "}
                                      <a
                                        href="javascript:void(0)"
                                        class="uc-option-btn pop-open"
                                      >
                                        {" "}
                                        <img
                                          src="https://www.ulektz.com/assets/img/home/options.svg"
                                          alt="icon"
                                        />{" "}
                                      </a>{" "}
                                      <a
                                        href="javascript:void(0)"
                                        class="uc-rb-btn uc-leave-btn opt-pop"
                                        onclick="removeBlockPopup(5641, 19,this)"
                                      >
                                        <span class="uc-ltc-pop-span d-none">
                                          <img
                                            src="https://www.ulektz.com/assets/img/community/Leave.svg"
                                            alt=""
                                          />
                                          Leave this Community
                                        </span>
                                      </a>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div> */}
                      <div>
                        <div class="uc-discover-ele-div">
                          <div class="uc-discover-ele-img-div">
                            <img src="https://www.ulektz.com/assets/img/no-content-img/Discover_Community-img-2.svg" />
                          </div>
                          <div class="uc-discover-ele-cnt-div">
                            <h3>Discover more community</h3>
                            <p>
                              Find other trusted communities that share and
                              support your goals.
                            </p>
                            <a
                              href="https://www.ulektz.com/search?searchValue=&amp;tag=community"
                              class="uc-discover-com-btn"
                            >
                              Discover
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-lg-12 col-md-12 col-sm-12 card"
                      style={{
                        maxHeight: "calc(100vh - 235px)",
                        border: "none",
                        borderRadius: "15px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#F4F7F9",
                          padding: "15px 15px",
                          marginLeft: "-12px",
                          marginRight: "-12px",
                          fontWeight: "600",
                          fontSize: "16px",
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                        }}
                      >
                        Communities you may like to join
                      </div>
                      <div
                        style={{
                          overflowY: "auto",

                          maxHeight: "calc(100vh - 335px)",
                          overflowX: "hidden",
                        }}
                      >
                        {" "}
                        {mostActiveCommunitiesArray.map((e) => {
                          return (
                            <>
                              <div className="row">
                                <div
                                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <span onclick="communityChangeNew(132)">
                                    <img
                                      src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/642847167.png"
                                      alt=""
                                      width="45px"
                                      heght="45px"
                                      onerror="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
                                    />
                                  </span>
                                </div>
                                <div className="col-9">
                                  <span
                                    class="uc-community-cnt"
                                    onclick="communityChangeNew(132)"
                                  >
                                    <h5>
                                      Alagappa University Directorate of
                                      Distance Education
                                    </h5>
                                    <div className="row">
                                      <div className="col-7">
                                        <p class="uc-communityLike-in">
                                          <span>
                                            Public Group
                                            <br />
                                            41866 members
                                          </span>
                                        </p>
                                      </div>
                                      <div
                                        className="col-5"
                                        style={{ paddingLeft: "0px" }}
                                      >
                                        <span
                                          class="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
                                          onClick={handleOpenjoinModal}
                                        >
                                          + Join
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                                <a
                                  data-id="132"
                                  href="javascript:void(0);"
                                  class="uc-com-join-prof-toggle"
                                ></a>
                              </div>
                              <hr
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                }}
                              />
                            </>
                          );
                        })}
                      </div>
                      <div>
                        <div class="uc-discover-ele-div">
                          <div class="uc-discover-ele-img-div">
                            <img src="https://www.ulektz.com/assets/img/no-content-img/Discover_Community-img-2.svg" />
                          </div>
                          <div class="uc-discover-ele-cnt-div">
                            <h3>Discover more community</h3>
                            <p>
                              Find other trusted communities that share and
                              support your goals.
                            </p>
                            <a
                              href="https://www.ulektz.com/search?searchValue=&amp;tag=community"
                              class="uc-discover-com-btn"
                            >
                              Discover
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Tab>
          <Tab title={<FirstTab />}></Tab>
        </Tabs>
      </div>
      <Modal
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-sm-30px"
        size="lg"
        // backdropClassName="childmodal"
        // backdrop="static"
        centered
        style={{ borderRadius: "55px" }}
        // onEntered={() => props.handleEntered}
      >
        <Modal.Body style={{ padding: "0px 0px" }}>
          <div class="uc-com-create-in">
            <div className="row">
              <div className="col-11">
                <h2
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    padding: "15px",
                  }}
                >
                  Create Community
                </h2>
              </div>
              <div
                className="col-1"
                style={{ padding: "15px", cursor: "pointer" }}
                onClick={() => setShow3(false)}
              >
                <img src="./images/closeOne.png"></img>
              </div>
            </div>

            <div
              style={{
                backgroundImage:
                  croppedImageCoverPhoto === undefined
                    ? "url(./images/common-background.png)"
                    : "url('" +
                      URL.createObjectURL(croppedImageCoverPhoto) +
                      "')",

                height: "112px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
                padding: "20px",
              }}
            >
              <img
                src="./images/Edit_post.svg"
                style={{
                  background: "#fff",
                  padding: "10px 10px",
                  borderRadius: "25%",
                  //   width:"50px"
                }}
                onClick={handleOpen}
              ></img>
            </div>
            <div class="uc-pro-left-img" style={{ padding: "20px 40px" }}>
              {croppedImage ? (
                <img
                  src={URL.createObjectURL(croppedImage)}
                  id="createComprofileimg"
                  alt=""
                  class="uc-profile-images clsProfileImg"
                  style={{
                    height: "112px",
                    width: "112px",
                    borderRadius: "50%",
                    marginTop: "-70px",
                  }}
                />
              ) : (
                <img
                  src="https://www.ulektz.com/assets/img/community/community-def-logo.png"
                  id="createComprofileimg"
                  alt=""
                  class="uc-profile-images clsProfileImg"
                  style={{
                    height: "112px",
                    width: "112px",
                    borderRadius: "50%",
                    marginTop: "-70px",
                  }}
                />
              )}

              <label class="uc-cam-icon" onclick="openCreateComProf_UpPop()">
                <img
                  id="cameraicon"
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                  style={{
                    backgroundColor: "#E4E3E0",
                    marginLeft: "-30px",
                    padding: "6px 6px",
                    borderRadius: "40%",
                  }}
                  onClick={handleOpen2}
                />
              </label>
            </div>
            <div class="uc-form-comm" style={{ padding: "20px 40px" }}>
              <input
                type="text"
                id="communityname"
                name="communityname"
                placeholder="Community Name *"
                class="uc-formControl form-control"
                maxlength="100"
                autocomplete="off"
              />
              <div class="clsCommunityCharCount" style={{ textAlign: "end" }}>
                0/100
              </div>
              {/* <p class="already-exist-show" style="display:none;">
                {" "}
                Name Already Taken
              </p> */}
            </div>
            <div class="uc-form-comm" style={{ padding: "20px 40px" }}>
              <textarea
                name="communityaddress"
                id="communityaddress"
                placeholder="Address *"
                maxlength="470"
                autocomplete="off"
                className="form-control"
                rows="4"
              ></textarea>
            </div>
            <div class="uc-form-comm" style={{ padding: "20px 40px" }}>
              <input
                type="text"
                name="communityemail"
                id="communityemail"
                placeholder="Email Address (Primary or Secondary) *"
                class="uc-formControl form-control"
                autocomplete="off"
              />
              <span class="errorMsg" id="emailErr"></span>
            </div>
            <div class="uc-form-comm" style={{ padding: "20px 40px" }}>
              <input
                type="tel"
                maxlength="10"
                name="communitycontactno"
                id="communitycontactno"
                placeholder="Phone / Mobile *"
                class="uc-formControl form-control"
                autocomplete="off"
              />
              <span class="errorMsg" id="phonenoErr"></span>
            </div>
            <div class="uc-form-comm" style={{ padding: "20px 40px" }}>
              <input
                type="text"
                name="communitywebsiteurl"
                id="communitywebsiteurl"
                placeholder="Website"
                class="uc-formControl form-control"
                autocomplete="off"
              />
              <span class="errorMsg" id="websiteurlErr"></span>
            </div>
            <div class="uc-form-comm" style={{ padding: "20px 40px" }}>
              <textarea
                name="aboutcommunity"
                id="aboutcommunity"
                placeholder="About Community *"
                autocomplete="off"
                className="form-control"
                rows="4"
              ></textarea>
            </div>
            <h2
              style={{
                padding: "0px 40px 0px 40px",
                fontWeight: "500",
                fontSize: "15px",
              }}
            >
              Community Type
            </h2>
            <div class="radioboxes">
              <p style={{ paddingLeft: "60px", paddingTop: "0px" }}>
                <label>
                  <div className="row">
                    <div className="col-1">
                      <input
                        type="radio"
                        name="comType"
                        id="academic_community"
                        value="1"
                      />
                    </div>
                    <div className="col" style={{ paddingLeft: "10px" }}>
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        Academic Community
                      </span>
                      <br />
                      <small style={{ fontSize: "12px" }}>
                        Schools, College and Universities
                      </small>
                    </div>
                  </div>
                </label>
              </p>
              <p style={{ paddingLeft: "60px", paddingTop: "15px" }}>
                <label>
                  <div className="row">
                    <div className="col-1">
                      <input
                        type="radio"
                        name="comType"
                        id="academic_community"
                        value="1"
                      />
                    </div>
                    <div className="col">
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        Professional & Social Community
                      </span>
                      <br />
                      <small style={{ fontSize: "12px" }}>
                        Associations, Societies, NGO's Etc.
                      </small>
                    </div>
                  </div>
                </label>
              </p>
              <p style={{ paddingLeft: "60px", paddingTop: "15px" }}>
                <label>
                  <div className="row">
                    <div className="col-1">
                      <input
                        type="radio"
                        name="comType"
                        id="academic_community"
                        value="1"
                      />
                    </div>
                    <div className="col">
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        Business Community
                      </span>
                      <br />
                      <small style={{ fontSize: "12px" }}>
                        Business Organisation & Corporates
                      </small>
                    </div>
                  </div>
                </label>
              </p>
            </div>
            <h2
              class="uc-crt-com-radio-hd"
              style={{
                padding: "0px 40px 0px 40px",
                fontWeight: "500",
                fontSize: "15px",
              }}
            >
              Community Access
            </h2>
            <div class="radioboxes">
              <p style={{ paddingLeft: "60px" }}>
                <label>
                  <div className="row">
                    <div className="col-1">
                      <input
                        type="radio"
                        name="comType"
                        id="academic_community"
                        value="1"
                      />
                    </div>
                    <div className="col">
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        Public Community
                      </span>
                      <br />
                      <small style={{ fontSize: "12px" }}>
                        Any one can join without admin approval
                      </small>
                    </div>
                  </div>
                </label>
              </p>
              <p style={{ paddingLeft: "60px", paddingTop: "15px" }}>
                <label>
                  <div className="row">
                    <div className="col-1">
                      <input
                        type="radio"
                        name="comType"
                        id="academic_community"
                        value="1"
                      />
                    </div>
                    <div className="col">
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        Private Community
                      </span>
                      <br />
                      <small style={{ fontSize: "12px" }}>
                        People can join only after admin approval
                      </small>
                    </div>
                  </div>
                </label>
              </p>
            </div>
            <div
              style={{
                textAlign: "end",
                backgroundColor: "#efefef",
                padding: "10px 10px",
              }}
            >
              <Button
                style={{
                  background: "#3CCE8F",
                  border: "none",
                  height: "35px",
                  //   width:"40px",
                  borderRadius: "15px",
                  //   display:"flex",
                  //   alignItems:"center",
                  //   justifyContent:"end"
                }}
              >
                create
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-sm-30px"
        size="md"
        backdropClassName="childmodal"
        // backdrop="static"
        centered
        // onEntered={() => props.handleEntered}
      >
        <Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
          <div className="row">
            <div className="col-11">
              <h2
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  padding: "15px",
                }}
              >
                Select Image
              </h2>
            </div>
            <div className="col-1" style={{ padding: "15px" }}>
              <img src="./images/closeOne.png"></img>
            </div>
          </div>
          <hr />
          {imageToCropCoverPhoto ? (
            <ImageCropper
              imageToCrop={imageToCropCoverPhoto}
              onImageCropped={(croppedImage) => {
                console.log(croppedImage);
                setCroppedImageCoverPhoto(croppedImage);
              }}
              coverphoto="coverphoto"
            />
          ) : (
            <div
              style={{
                backgroundColor: "#efefef",
                height: "50vh",
                width: "34vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="./images/cover_image_icon.svg"
                style={{ height: "90px" }}
              ></img>
            </div>
          )}

          <div
            style={{
              fontSize: "13px",
              fontWeight: "300",
              textAlign: "center",
              paddingTop: "10px",
            }}
          >
            Recommended size - 1920 x 1080px, Max. 2 MB
          </div>
          <div
            className="row"
            style={{ backgroundColor: "#efefef", padding: "10px 10px" }}
          >
            <div className="col-8">
              <input
                type="file"
                id="file"
                ref={inputFileCoverPhoto}
                style={{ display: "none" }}
                onChange={onUploadFileCoverPhoto}
              />
              <button
                onClick={onButtonClickCoverPhoto}
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button>
              {/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
            </div>
            <div className="col-2">
              <div>
                <Button
                  style={{
                    background: "#fff",
                    border: "none",
                    height: "35px",
                    color: "#000",
                    border: "1px solid #000",
                    borderRadius: "15px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="col-2">
              <div>
                <Button
                  style={{
                    background: "#3CCE8F",
                    border: "none",
                    height: "35px",
                    //   width:"40px",
                    borderRadius: "15px",
                    //   display:"flex",
                    //   alignItems:"center",
                    //   justifyContent:"end"
                  }}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        dialogClassName="modal-sm-30px"
        size="md"
        backdropClassName="childmodal"
        // backdrop="static"
        centered
        // onEntered={() => props.handleEntered}
      >
        <Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
          <div className="row">
            <div className="col-11">
              <h2
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  padding: "15px",
                }}
              >
                Select Image
              </h2>
            </div>
            <div
              className="col-1"
              style={{ padding: "15px", marginLeft: "-20px" }}
            >
              <img src="./images/closeOne.png"></img>
            </div>
          </div>
          <hr style={{ margin: "0px" }} />
          <ImageCropper
            imageToCrop={imageToCrop}
            onImageCropped={(croppedImage) => {
              setCroppedImage(croppedImage);
            }}
          />
          {imageToCrop === undefined && (
            <div
              style={{
                //   backgroundColor: "#efefef",
                height: "50vh",
                width: "34vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="./images/Communities-icon.svg"
                style={{ height: "42vh" }}
              ></img>
            </div>
          )}

          <div
            className="row"
            style={{
              backgroundColor: "#efefef",
              padding: "10px 10px 10px 10px",
            }}
          >
            <div className="col-8">
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={onUploadFile}
              />
              <button
                onClick={onButtonClick}
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button>
              {/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
            </div>
            <div className="col-2"></div>
            <div className="col-2">
              <div>
                <Button
                  style={{
                    background: "#3CCE8F",
                    border: "none",
                    height: "35px",
                    //   width:"40px",
                    borderRadius: "15px",
                    //   display:"flex",
                    //   alignItems:"center",
                    //   justifyContent:"end"
                  }}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showJoinModal}
        onHide={handleCloseJoinModal}
        dialogClassName="modal-sm-30px"
        size="md"
        backdropClassName="childmodal"
        // backdrop="static"
        centered
        // onEntered={() => props.handleEntered}
      >
        <Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
          <div className="row">
            <div className="col-11" style={{ paddingLeft: "0px" }}>
              <h2
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  padding: "10px",
                  marginBottom: "0px",
                  display: "flex",
                }}
              >
                Join - Nuclear Physics &nbsp;
                <span class="public-text" onclick="communityChangeNew(5421)">
                  <span class="uc-star-count">
                    <a href="javascript:void(0)" class="cmn-btn uc-normal-btn">
                      <img src="https://www.ulektz.com/assets/img/profile/Public_icon.svg" />{" "}
                      Public
                    </a>
                  </span>
                </span>{" "}
              </h2>
            </div>
            <div
              className="col-1"
              style={{ padding: "10px", cursor: "pointer" }}
              onClick={handleCloseJoinModal}
            >
              <img src="./images/closeOne.png"></img>
            </div>
          </div>
          <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
          <div
            class="uc-pro-left-img"
            style={{ padding: "20px 40px", textAlign: "center" }}
          >
            {croppedImageProfilePhoto === undefined ? (
              <img
                src="./images/krishigaplogo.png"
                id="createComprofileimg"
                alt=""
                class="uc-profile-images clsProfileImg"
                style={{
                  height: "112px",
                  width: "112px",
                  borderRadius: "50%",
                  border: "1px solid #ddd",
                }}
              />
            ) : (
              <img
                src={URL.createObjectURL(croppedImageProfilePhoto)}
                id="createComprofileimg"
                alt=""
                class="uc-profile-images clsProfileImg"
                style={{
                  height: "112px",
                  width: "112px",
                  borderRadius: "50%",
                  border: "1px solid #ddd",
                }}
              />
            )}

            <label class="uc-cam-icon" onclick="openCreateComProf_UpPop()">
              <img
                id="cameraicon"
                src="https://www.ulektz.com/assets/img/home/camera.svg"
                alt=""
                style={{
                  backgroundColor: "#E4E3E0",
                  marginLeft: "-30px",
                  padding: "6px 6px",
                  borderRadius: "40%",
                  marginTop: "75px",
                }}
                onClick={handleOpenprofilePhotoModal}
              />
            </label>
          </div>
          <div style={{ padding: "15px 45px 10px" }}>
            <label for="name" style={{ paddingBottom: "5px" }}>
              Name*
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
            ></input>
            <label
              for="name"
              style={{ paddingTop: "10px", paddingBottom: "5px" }}
            >
              Profile Headline*
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Profile Headline"
            ></input>
            <label
              for="name"
              style={{ paddingTop: "10px", paddingBottom: "5px" }}
            >
              Location*
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Location"
            ></input>
            <label
              for="name"
              style={{ paddingTop: "10px", paddingBottom: "5px" }}
            >
              Email*
            </label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Email"
            ></input>
            <label
              for="name"
              style={{ paddingTop: "10px", paddingBottom: "5px" }}
            >
              Mobile Number*
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Mobile Number"
            ></input>
            <label
              for="name"
              style={{ paddingTop: "10px", paddingBottom: "5px" }}
            >
              Select Role*
            </label>
            <select
              class="form-control select_role select-hidden"
              id="select_role"
              name="formFieldName[select_role]"
              required=""
              profession-selected="0"
            >
              <option value="">Select</option>
              <option value="4">Member</option>
              <option value="17">Alumni/Guest</option>
            </select>
          </div>
          <div
            className="row"
            style={{
              padding: "15px 15px 15px 15px",
              display: "flex",
              alignItems: "center",
              background: "#F1F1F1",
              //   marginLeft: "0px",
              //   marginRight:"0px"
            }}
          >
            <div className="col-9"></div>
            <div className="col-3">
              <span
                className="col-1"
                style={{
                  //   border: "1px solid #ddd",
                  borderRadius: "5px",
                  padding: "4px 15px 6px 20px",
                  //   width: "auto",
                  paddingLeft: "20px",
                  color: "#fff",
                  background: "#E23F1C",
                }}
              >
                + Join
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showProfilePhotoModal}
        onHide={handleCloseProfilePhotoModal}
        dialogClassName="modal-sm-30px"
        size="md"
        backdropClassName="childmodal"
        // backdrop="static"
        centered
        // onEntered={() => props.handleEntered}
      >
        <Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
          <div className="row">
            <div className="col-11">
              <h2
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  padding: "15px",
                }}
              >
                Profile Photo
              </h2>
            </div>
            <div className="col-1" style={{ padding: "15px" }}>
              <img src="./images/closeOne.png"></img>
            </div>
          </div>
          <hr />
          {imageToCropProfilePhoto ? (
            <ImageCropper
              imageToCrop={imageToCropProfilePhoto}
              onImageCropped={(croppedImage) => {
                console.log(croppedImage);
                setCroppedImageProfilePhoto(croppedImage);
              }}
              coverphoto="coverphoto"
            />
          ) : (
            <div
              style={{
                backgroundColor: "#efefef",
                height: "50vh",
                width: "34vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src="./images/profile.svg" style={{ height: "90px" }}></img>
            </div>
          )}

          <div
            style={{
              fontSize: "13px",
              fontWeight: "300",
              textAlign: "center",
              paddingTop: "10px",
            }}
          >
            Recommended size - 1920 x 1080px, Max. 2 MB
          </div>
          <div
            className="row"
            style={{ backgroundColor: "#efefef", padding: "10px 10px" }}
          >
            <div className="col-8">
              <input
                type="file"
                id="file"
                ref={inputFileProfilePhoto}
                style={{ display: "none" }}
                onChange={onUploadFileProfilePhoto}
              />
              <button
                onClick={onButtonClickProfilePhoto}
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button>
              {/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
            </div>
            <div className="col-2">
              <div>
                <Button
                  style={{
                    background: "#fff",
                    border: "none",
                    height: "35px",
                    color: "#000",
                    border: "1px solid #000",
                    borderRadius: "15px",
                  }}
                  onClick={handleCloseProfilePhotoModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="col-2">
              <div>
                <Button
                  style={{
                    background: "#3CCE8F",
                    border: "none",
                    height: "35px",
                    //   width:"40px",
                    borderRadius: "15px",
                    //   display:"flex",
                    //   alignItems:"center",
                    //   justifyContent:"end"
                  }}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Community;
