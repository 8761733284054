import React from "react";
import "../Menu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPaperPlane,
  faPhone,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from "react";
import Navbar from "./navbar";
import Homecarousel from "./homecarousel";
import Footer from "./footer";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";

const Employerregister = () => {

  const[name,setname]=useState("");
  const[email,setemail]=useState("");
  const[number,setnumber]=useState("");
  const[Organization,setOrganization]=useState("");
  const[username,setusername]=useState("");
  const [password, setpassword] = useState("");
    const { profile, auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {

		e.preventDefault();
		console.log({ name, email, number, Organization, username, password });


		try {
			const res = await postDataAPI(
				"/createemployerRegister",
				{
					name,
					email,
					mobile: number,
					Organization,
					username,
					password,
				},
				auth.token,
			);
			console.log("res", res);
			if (res.status === 200) {
				alert("Thank you for registering. We will get back to you soon");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};
  return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="emp">
				<div className="emp-box">
					<div className="emp-heading">
						<h2>Employer Register</h2>
					</div>
					<div className="emp-boxIn">
						<div className="emp-greenbox">
							<div>
								<h4 className="emp-greenbox-heading">Fill the form</h4>
							</div>
							<div>
								<form onSubmit={handleSubmit}>
									<div className="emp-inputbox">
										<span className="emp-inputbox-input">
											<input
												type="text"
												placeholder="Your Name"
												onChange={(e) => setname(e.target.value)}
											/>
										</span>
										<div className="emp-Icon">
											<FontAwesomeIcon icon={faUser} />
										</div>
									</div>

									<div className="emp-inputbox">
										<span className="emp-inputbox-input">
											<input
												type="text"
												placeholder="Email"
												onChange={(e) => setemail(e.target.value)}
											/>
										</span>
										<div className="emp-Icon">
											<FontAwesomeIcon icon={faPaperPlane} />
										</div>
									</div>

									<div className="emp-inputbox">
										<span className="emp-inputbox-input">
											<input
												type="text"
												placeholder="Phone Number"
												onChange={(e) => setnumber(e.target.value)}
											/>
										</span>
										<div className="emp-Icon">
											<FontAwesomeIcon icon={faPhone} />
										</div>
									</div>

									<div className="emp-inputbox">
										<span className="emp-inputbox-input">
											<input
												type="text"
												placeholder="Organization Name"
												onChange={(e) => setOrganization(e.target.value)}
											/>
										</span>
										<div className="emp-Icon">
											<FontAwesomeIcon icon={faUser} />
										</div>
									</div>

									<div className="emp-inputbox">
										<span className="emp-inputbox-input">
											<input
												type="text"
												placeholder="User Name"
												onChange={(e) => setusername(e.target.value)}
											/>
										</span>
										<div className="emp-Icon">
											<FontAwesomeIcon icon={faUser} />
										</div>
									</div>

									<div className="emp-inputbox">
										<span className="emp-inputbox-input">
											<input
												type="text"
												placeholder="Passsword"
												onChange={(e) => setpassword(e.target.value)}
											/>
										</span>
										<div className="emp-Icon">
											<FontAwesomeIcon icon={faKey} />
										</div>
									</div>

									<button
										type="submit"
										className="emp-submitbtn"
										>
										SUBMIT
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Employerregister;
