import React from "react";

const Sidenavbar = () => {
	return (
		<>
			<div
				className="col-3 admin"
				style={{
					backgroundColor: "#F7F4F4",
					// margin: "20px",
					paddingTop: "20px",
				}}>
				<ul class="">
					<li class="">
						<a href="/usermanagement">
							<i style={{ color: "#2d947a" }} class="fa fa-user"></i>
							&nbsp;
							<span className="admin-text">User Management</span>
						</a>
					</li>
					<hr />
					<li class="">
						<a href="/instructorledmanagement">
							{/* <i style={{ color: "#2d947a" }} class="fa fa-teacher"></i> */}
							<i style={{ color: "#2d947a" }} class="fa fa-users"></i>
							&nbsp;
							<span className="admin-text">Instructor Led Management</span>
						</a>
					</li>
					<hr />
					<li class="">
						<a href="/coursemanagement">
							<i style={{ color: "#2d947a" }} class="fa fa-book"></i>
							&nbsp;
							<span className="admin-text">Course Management</span>
						</a>
					</li>
					<hr />
					<li class="">
						<a href="/digitallibrarymanagement">
							<i style={{ color: "#2d947a" }} class="fa fa-file"></i>
							&nbsp;
							<span className="admin-text">Digital Library Management</span>
						</a>
					</li>
					<hr />
					<li class="">
						<a href="/groupmanagement">
							{/* <i style={{ color: "#2d947a" }} class="fa fa-teacher"></i> */}
							<i style={{ color: "#2d947a" }} class="fa fa-users"></i>
							&nbsp;
							<span className="admin-text">Group Management</span>
						</a>
					</li>
					<hr />
					<li class="">
						<a href="/regulatorycompliencesmanagement">
							<i style={{ color: "#2d947a" }} class="fa fa-gears"></i>
							&nbsp;
							<span className="admin-text">
								Regulatory Compliances Management
							</span>
						</a>
					</li>
					<hr />
					<li class="">
						<a href="/placementmanagement">
							<i style={{ color: "#2d947a" }} class="fa fa-graduation-cap"></i>
							&nbsp;
							<span className="admin-text">Placements Management</span>
						</a>
					</li>
					<hr />
					<li class="">
						<a href="/classroommanagement">
							<i style={{ color: "#2D947A" }} class="fa fa-users"></i>
							&nbsp;
							<span className="admin-text">Class Room Management</span>
						</a>
					</li>
				</ul>
			</div>
		</>
	);
};
export default Sidenavbar;
