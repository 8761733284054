import {
	faPaperPlane,
	faPhone,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Multiselect } from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import Adminnavbar from "./adminnavbar";
import Footer from "./footer";
import LoginRequest from "./loginRequest";
import Sidenavbar from "./sidenav";

const Superadmin = () => {
	const { profile, auth } = useSelector((state) => state);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [name, setname] = useState("");
	const [number, setnumber] = useState("");
	const [email, setemail] = useState("");
	const [password, setPassword] = useState("");
	const [dropdown, setdropdown] = useState([]);

	const [showPassword, setShowPassword] = useState(false);
	const [coursesArray, setCoursesArray] = useState([]);

	const dispatch = useDispatch();

	const [instructors, setInstructors] = useState([]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log({ name, number, email, dropdown, password });

		try {
			const res = await postDataAPI("createinstructors", {
				name,
				mobile: number,
				email,
				courses_approved: JSON.stringify(dropdown),
				password,
			});
			console.log("res", res);
			if (res.status === 200) {
				alert("Thank you for registering. We will get back to you soon");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	useEffect(() => {
		if (auth.token) {
			getDataAPI(
				`getAllCoursesNames/${auth.user.id}/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
				auth.token
			).then((res) => {
				console.log("res my courses", res.data);
				setCoursesArray(res.data.courses);
			});
		}
	}, [auth]);

	const onSelect = (option) => {
		console.log("option", option);

		setdropdown(option);
	};

	const onRemove = (option) => {
		console.log("option", option);

		setdropdown(option);
	};

	useEffect(() => {
		console.log("dropdown", dropdown);
	}, [dropdown]);

	useEffect(() => {
		const fetchInstructors = async () => {
			try {
				const res = await getDataAPI("/getAllInstructors", auth.token);
				setInstructors(res.data.instructors);
			} catch (err) {
				console.error("Error fetching instructors:", err);
			}
		};

		if (auth.token) {
			fetchInstructors();
		}
	}, [auth.token]);
	console.log(instructors);
	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<div className="js">
					<div
						className="x-style"
						onClick={handleClose}
						style={{ fontSize: "" }}>
						{" "}
						<span style={{ cursor: "pointer" }}>X</span>
					</div>
					<div className="js-boxIn">
						<div className="js-boxIn-content">
							<div className="ai-greenbox">
								<div>
									<h4 className="js-greenbox-heading">Add Instructor</h4>
								</div>
								<div>
									<form>
										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													placeholder="Full Name"
													onChange={(e) => setname(e.target.value)}
												/>
											</span>
											<div className="js-Icon">
												<FontAwesomeIcon icon={faUser} />
											</div>
										</div>
										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													placeholder="Email"
													onChange={(e) => setemail(e.target.value)}
												/>
											</span>
											<div className="js-Icon">
												<FontAwesomeIcon icon={faPaperPlane} />
											</div>
										</div>
										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													placeholder="Phone Number"
													onChange={(e) => setnumber(e.target.value)}
												/>
											</span>
											<div className="js-Icon">
												<FontAwesomeIcon icon={faPhone} />
											</div>
										</div>
										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<Multiselect
													className=""
													options={coursesArray}
													onSelect={(e) => onSelect(e)}
													onRemove={(e) => onRemove(e)}
													displayValue="coursetitle"
													isObject={true}></Multiselect>
											</span>
											<div className="js-Icon"></div>
										</div>
										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													placeholder="Password"
													type={showPassword ? "text" : "password"}
													onChange={(e) => setPassword(e.target.value)}
												/>
											</span>
											<span
												className="toggle-password d-flex align-items-center"
												onClick={() => setShowPassword(!showPassword)}>
												{showPassword ? (
													<>
														<FaEyeSlash className="me-1" />
													</>
												) : (
													<>
														<FaEye className="me-1" />
													</>
												)}
											</span>
										</div>
										<button
											type="submit"
											className="js-submitbtn"
											onClick={handleSubmit}>
											SUBMIT
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Adminnavbar />
			{/* <Homecarousel /> */}
			{auth.user ? (
				<div
					className=""
					style={{ backgroundColor: "#ffffff", padding: "100px 30px" }}>
					<div className="button-format">
						<div className="dashboard-text">Super Admin Dashboard</div>

						<button className="addinstructor-btn" onClick={handleShow}>
							+ ADD INSTRUCTOR
						</button>
					</div>
					<div id="" className="" style={{}}>
						<div className="">
							<div className="row format">
								<Sidenavbar />
								<div className="col-8">
									<div
										className="row format"
										style={{
											paddingTop: "20px",
											paddingBottom: "20px",
											backgroundColor: "#A7D588",
										}}>
										<div
											className="col-4 text-format"
											style={{ backgroundColor: "#24A2B3" }}>
											<div className="text1">{instructors.length}</div>
											<div className="text1">Instructors</div>
										</div>{" "}
										{/* <div
											className="col text-format"
											style={{ backgroundColor: "#E96CB4" }}
										>
											<div className="text1">200+</div>
											<div className="text1">Instructor Led</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#7F2DA2" }}>
											{" "}
											<div className="text1">200+</div>
											<div className="text1">Courses</div>
										</div> */}
									</div>
									{/* <div className="row format" style={{ paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#C78B62" }}>
											<div className="text1">200+</div>
											<div className="text1">Digital Library</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#4C87B8" }}>
											<div className="text1">200+</div>
											<div className="text1">Regulatory Compliances</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#8572D6" }}>
											{" "}
											<div className="text1">200+</div>
											<div className="text1">Placements</div>
										</div>
									</div> */}
								</div>
							</div>

							<div className="row" style={{ padding: "100px" }}>
								<div className="col">
									<h4 className="instructorlist-text">Instructors List</h4>
									<Table>
										<thead id="pl">
											<tr>
												<th>S.No</th>
												<th>Name</th>
												<th>Email Address</th>
												<th>Phone No</th>
											</tr>
										</thead>
										{instructors.map((instructor, index) => (
											<tr key={instructor.id}>
												<td>{index + 1}</td>
												<td>{instructor.name}</td>
												<td>{instructor.email}</td>
												<td>{instructor.mobile}</td>
											</tr>
										))}
									</Table>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoginRequest />
			)}
			<Footer />
		</>
	);
};
export default Superadmin;
