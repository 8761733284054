import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { postDataAPI } from "../utils/fetchData";

const Login = () => {
	const initialState = { email: "", password: "" };
	const [userData, setUserData] = useState(initialState);
	const { email, password } = userData;
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState(null);
	const [successMessage, setSuccessMessage] = useState("");
	const history = useHistory();

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setError(null);
		setSuccessMessage("");
		if (!email || !password) {
			setError("Please enter both email and password.");
			return;
		}

		try {
			const response = await postDataAPI("/instructorlogin", {
				email,
				password,
			});

			if (response.data && response.data.access_token) {
				localStorage.setItem("access_token", response.data.access_token);
				localStorage.setItem(
					"instructor",
					JSON.stringify(response.data.instructor)
				);

				setSuccessMessage("Email and Password Matched! Logging you in...");

				setTimeout(() => {
					history.push({
						pathname: "/listofinstructorcourses",
						state: { email },
					});
				}, 2000);
			} else {
				setError("Unexpected response from the server.");
			}
		} catch (err) {
			console.error("Login failed", err);

			if (err.response && err.response.data) {
				setError(
					err.response.data.message || "Email or Password is incorrect."
				);
			} else {
				setError("An error occurred. Please try again.");
			}
		}
	};

	return (
		<div style={{ backgroundColor: "white" }}>
			<div className="spacer"></div>
			<div id="testlogin">
				<div className="row">
					<div className="col-1"></div>
					<div className="col">
						<h4 className="login-headtext">Instructor Led Login</h4>
					</div>
				</div>

				<div className="row">
					<div className="col-1"></div>
					<div className="col-10 block">
						<form onSubmit={handleSubmit}>
							<div className="row">
								<label className="email-label">Username or Email address</label>
								<div className="email-input">
									<input
										className="email-input"
										type="email"
										onChange={handleChangeInput}
										value={email}
										name="email"
										required
									/>
								</div>
							</div>

							<div className="row">
								<div className="d-flex justify-content-between align-items-center w-100">
									<label className="password-label">Password</label>
									<span
										className="toggle-password d-flex align-items-center"
										onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? (
											<>
												<FaEyeSlash className="me-1" />
												Hide Password
											</>
										) : (
											<>
												<FaEye className="me-1" />
												Show Password
											</>
										)}
									</span>
								</div>
							</div>

							<div className="row">
								<div className="password-input">
									<input
										className="password-input"
										type={showPassword ? "text" : "password"}
										onChange={handleChangeInput}
										value={password}
										name="password"
										required
									/>
								</div>
							</div>

							{error && <p className="error-message">{error}</p>}
							{successMessage && (
								<p className="success-message">{successMessage}</p>
							)}

							<button className="login-button mt-3" type="submit">
								Log In
							</button>
						</form>
					</div>

					<div className="col-1"></div>
				</div>
			</div>
			<div className="spacer"></div>
		</div>
	);
};

export default Login;
