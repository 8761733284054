import React, { useEffect, useState } from "react";

const Question = ({ question, index, onSelectAnswer, selectedAnswer }) => {
	const [selectedOption, setSelectedOption] = useState(selectedAnswer);
	useEffect(() => {
		setSelectedOption(selectedAnswer);
	}, [selectedAnswer]);
	const handleOptionChange = (optionIndex, option) => {
		setSelectedOption(option);
		onSelectAnswer(index, optionIndex, option);
	};
	return (
		<div>
			<h3>
				{index + 1}. {question.question}
			</h3>
			<ul>
				{question.options.map((option, i) => {
					const label = String.fromCharCode(97 + i);
					return (
						<div key={i} style={{ border: "1px solid #ddd" }}>
							<div className="row" style={{ padding: "0px" }}>
								<div
									className="col-1"
									style={{
										display: "flex",
										alignItems: "end",
										justifyContent: "end",
										fontWeight: "500",
									}}>
									{label.toUpperCase()}.
								</div>
								<div
									className="col-9"
									style={{
										fontWeight: "500",
										paddingTop: "20px",
										marginLeft: "30px",
									}}>
									{option}
								</div>
								<div
									className="col-1"
									style={{
										display: "flex",
										alignItems: "end",
										justifyContent: "end",
									}}>
									<label key={index} style={{ paddingTop: "20px" }}>
										<input
											type="radio"
											value={option}
											name={`question-${index}`}
											checked={selectedOption === option}
											onChange={() => handleOptionChange(i, option)}
										/>
									</label>
								</div>
							</div>
							<br />
						</div>
					);
				})}
			</ul>
		</div>
	);
};
export default Question;
