import React, { useEffect, useState } from "react";
// import "../Menu.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { adminLogin, login } from "../redux/actions/authAction";
import Footer from "./footer";
import Navbar from "./navbar";
const Login = () => {
	const initialState = { email: "", password: "" };
	const [userData, setUserData] = useState(initialState);
	const [userType, setUserType] = useState(false);
	const { email, password } = userData;

	const [typePass, setTypePass] = useState(false);

	const { auth } = useSelector((state) => state);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (auth.token) history.push("/");
	}, [auth.token, history]);

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!userType) {
			dispatch(login(userData));
		} else {
			dispatch(adminLogin(userData));
		}
	};

	return (
		<>
			<Navbar />
			<div className="" style={{ backgroundColor: "#fff" }}>
				<div class="spacer"></div>
				<div className="row">
					<div className="col-1"></div>
					<div className="col-10 block">
						<form onSubmit={handleSubmit}>
							<div className="row">
								<label className="email-label">Username or Email address</label>
								<div className="email-input">
									<input
										className="email-input"
										type="email"
										onChange={handleChangeInput}
										value={email}
										name="email"
									/>
								</div>
							</div>
							<div class="spacer"></div>
							<div className="row">
								<label className="password-label">Password</label>
								<div className="password-input">
									<input
										className="password-input"
										type="password"
										onChange={handleChangeInput}
										value={password}
										name="password"
									/>
								</div>
							</div>
							<div class="spacer"></div>
							<div className="">
								<input className="checkbox" type="checkbox" />
								<label>Remember Me</label>
							</div>
							<div class="spacer"></div>
							<button className="login-button" type="submit">
								Login
							</button>
							<div class="spacer"></div>
							<br />
							<button className="Lost-button" type="submit">
								Join Now
							</button>
							{"|"}
							<button className="Lost-button" type="submit">
								Lost Password
							</button>
						</form>
					</div>
					<div className="col-1"></div>
				</div>
				<div class="spacer"></div>
			</div>
			<Footer />
		</>
	);
};
export default Login;
