import {
	deleteDataAPI,
	getDataAPI,
	patchDataAPI,
	postDataAPI,
} from "../../utils/fetchData";
import { imageUpload } from "../../utils/imageUpload";
import { GLOBALTYPES } from "./globalTypes";
import { createNotify, removeNotify } from "./notifyAction";

export const POST_TYPES = {
	CREATE_POST: "CREATE_POST",
	LOADING_POST: "LOADING_POST",
	GET_POSTS: "GET_POSTS",
	UPDATE_POST: "UPDATE_POST",
	GET_POST: "GET_POST",
	DELETE_POST: "DELETE_POST",
	REPORT_POST: "REPORT_POST",
	SAVE_POST: "SAVE_POST",
};

export const createPost =
	({ content, subject, groupid, images, auth, socket }) =>
	async (dispatch) => {
		let media = [];

		try {
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

			if (images.length > 0) {
				media = await imageUpload(images);
			}

			const res = await postDataAPI(
				`posts/${groupid}`,
				{ content, subject, groupid, images },
				auth.token
			);
			if (res.status === 200) {
				window.location.reload();
			}
			dispatch({
				type: POST_TYPES.CREATE_POST,
				payload: { ...res.data.newPost, user: auth.user },
			});

			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });

			// todo notification
			const msg = {
				id: res.data.newPost.id,
				text: "Added a new post.",
				recipients: res.data.newPost.users.followers,
				url: `/post/${res.data.newPost.id}`,
				content,
				image: media[0].url,
			};

			dispatch(createNotify({ msg, auth, socket }));
		} catch (err) {
			console.log(err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: {
					error: err.response.data.msg,
				},
			});
		}
	};

export const getPosts = (groupid) => async (dispatch) => {
	try {
		dispatch({ type: POST_TYPES.LOADING_POST, payload: true });
		const res = await getDataAPI("posts", { groupid });
		console.log("res", res.data);
		dispatch({ type: POST_TYPES.GET_POSTS, payload: { ...res.data, page: 2 } });

		dispatch({ type: POST_TYPES.LOADING_POST, payload: false });
	} catch (err) {
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: {
				error: err.response.data.msg,
			},
		});
	}
};

export const updatePost =
	({ content, images, auth, status }) =>
	async (dispatch) => {
		let media = [];
		const imgNewUrl = images.filter((img) => !img.url);
		const imgOldUrl = images.filter((img) => img.url);
		if (
			status.content === content &&
			imgNewUrl.length === 0 &&
			imgOldUrl.length === status.images.length
		) {
			return;
		}
		try {
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
			if (imgNewUrl.length > 0) {
				media = await imageUpload(imgNewUrl);
			}
			const res = await patchDataAPI(
				`post/${status.id}`,
				{ content, images: [...imgOldUrl, ...media] },
				auth.token
			);
			if (res.status === 200) {
				window.location.reload();
			}
			dispatch({ type: POST_TYPES.UPDATE_POST, payload: res.data.newPost });
			dispatch({ type: GLOBALTYPES.ALERT, payload: { success: res.data.msg } });
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: {
					error: err.response.data.msg,
				},
			});
		}
	};

export const likePost =
	({ post, auth, socket }) =>
	async (dispatch) => {
		const newPost = { ...post, likes: [...post.likes, auth.user] };
		console.log("newPost", newPost);

		dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost });
		// socket.emit("likePost", newPost);

		try {
			await getDataAPI(`post/${post.id}/like`, auth.token);

			// todo notification
			const msg = {
				// id: auth.user.id,
				text: "Liked your post.",
				recipients: [post.users.id],
				url: `/post/${post.id}`,
				content: post.content,
				image: post.images[0].url,
			};

			dispatch(createNotify({ msg, auth, socket }));
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: {
					error: err.response.data.msg,
				},
			});
		}
	};

export const unLikePost =
	({ post, auth, socket }) =>
	async (dispatch) => {
		const newPost = {
			...post,
			likes: post.likes.filter((like) => like.id !== auth.user.id),
		};

		dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost });
		// socket.emit("unLikePost", newPost);

		try {
			await getDataAPI(`post/${post.id}/unlike`, auth.token);

			// todo notification
			const msg = {
				id: auth.user.id,
				text: "Liked your post.",
				recipients: [post.users.id],
				url: `/post/${post.id}`,
			};

			dispatch(removeNotify({ msg, auth, socket }));
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: {
					error: err.response.data.msg,
				},
			});
		}
	};

export const getPost =
	({ detailPost, id, auth }) =>
	async (dispatch) => {
		if (detailPost.every((post) => post.id !== id)) {
			try {
				const res = await getDataAPI(`post/${id}`);
				dispatch({ type: POST_TYPES.GET_POST, payload: res.data.post });
			} catch (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: {
						error: err.response.data.msg,
					},
				});
			}
		}
	};

export const deletePost =
	({ post, auth, socket }) =>
	async (dispatch) => {
		dispatch({ type: POST_TYPES.DELETE_POST, payload: post });

		try {
			const res = await deleteDataAPI(`post/${post.id}`, auth.token);

			// todo notification
			const msg = {
				id: post.id,
				text: "Added a new post.",
				recipients: res.data.newPost.users.followers,
				url: `/post/${post.id}`,
			};

			dispatch(removeNotify({ msg, auth, socket }));
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: {
					error: err.response.data.msg,
				},
			});
		}
	};

export const reportPost =
	({ post, auth }) =>
	async (dispatch) => {
		const reportExist = post.reports.find((report) => report === auth.user.id);

		if (reportExist && reportExist.length > 0) {
			return dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: "You have already reported this post." },
			});
		}
		const newPost = { ...post };
		newPost.reports.push(auth.user.id);

		dispatch({ type: POST_TYPES.REPORT_POST, payload: newPost });

		try {
			const res = await patchDataAPI(
				`post/${post.id}/report`,
				null,
				auth.token
			);
			dispatch({ type: GLOBALTYPES.ALERT, payload: { success: res.data.msg } });
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: {
					error: err.response.data.msg,
				},
			});
		}
	};

export const savePost =
	({ post, auth }) =>
	async (dispatch) => {
		const newUser = { ...auth.user, saved: [...auth.user.saved, post.id] };

		dispatch({ type: GLOBALTYPES.AUTH, payload: { ...auth, user: newUser } });

		try {
			await patchDataAPI(`savePost/${post.id}`, null, auth.token);
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: {
					error: err.response.data.msg,
				},
			});
		}
	};

export const unSavePost =
	({ post, auth }) =>
	async (dispatch) => {
		const newUser = {
			...auth.user,
			saved: auth.user.saved.filter((id) => id !== post.id),
		};

		dispatch({ type: GLOBALTYPES.AUTH, payload: { ...auth, user: newUser } });

		try {
			await patchDataAPI(`unSavePost/${post.id}`, null, auth.token);
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: {
					error: err.response.data.msg,
				},
			});
		}
	};
