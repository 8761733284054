import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Adminnavbar from "./adminnavbar";
import Homecarousel from "./homecarousel";
import Footer from "./footer";


const allnotifications =()=>{
     const allnotifications = [
       {
         id: "1",
         massage: "you got one notification",
       },
       {
         id: "2",
         massage: "you got one notification",
       },
       {
         id: "3",
         massage: "you got one notification",
       },
       {
         id: "4",
         massage: "you got one notification",
       },
     ];
    return (
      <>
        <Adminnavbar />
        <div
          id="notifytab-pane"
          className="notifycontainer"
          style={{ backgroundColor: "", padding: "50px" }}
        >
          <Tabs defaultActiveKey="first">
            <Tab eventKey="first" title="Job-seekar">
              <div className="" style={{ paddingTop: "20px" }}>
                {allnotifications.map((k) => {
                  return (
                    <>
                      <div className="notifyfont">{k.massage}</div>
                      <div className="" >
                        {" "}
                        <hr />
                      </div>
                    </>
                  );
                })}
              </div>
            </Tab>
            <Tab eventKey="second" title="Employeer">
              <div className="" style={{ paddingTop: "20px" }}>
                {allnotifications.map((k) => {
                  return (
                    <>
                      <div className="notifyfont">{k.massage}</div>
                      <hr />
                    </>
                  );
                })}
              </div>
            </Tab>
            <Tab eventKey="third" title="Post">
              <div className="" style={{ paddingTop: "20px" }}>
                {allnotifications.map((k) => {
                  return (
                    <>
                      <div className="notifyfont">{k.massage}</div>
                      <hr />
                    </>
                  );
                })}
              </div>
            </Tab>
            <Tab eventKey="fourth" title="Group">
              <div className="" style={{ paddingTop: "20px" }}>
                {allnotifications.map((k) => {
                  return (
                    <>
                      <div className="notifyfont">{k.massage}</div>
                      <hr />
                    </>
                  );
                })}
              </div>
            </Tab>
          </Tabs>
        </div>
        <Footer />
      </>
    );
}
export default allnotifications;