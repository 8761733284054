import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDataAPI } from "../utils/fetchData";
import Adminnavbar from "./adminnavbar";
import Footer from "./footer";
import LoginRequest from "./loginRequest";
import Sidenavbar from "./sidenav";

const Digitallibrarymanagement = () => {
	const { profile, auth } = useSelector((state) => state);
	const [dlDetails, setDlDetails] = useState("");

	useEffect(() => {
		getDataAPI(`getdigitallibrarycount`, auth.token).then((res) => {
			console.log("res getdigitallibrarycount", res.data);
			setDlDetails(res.data.dldetails);
		});
	}, [auth.token]);

	return (
		<>
			<Adminnavbar />
			{/* <Homecarousel /> */}
			{auth.user ? (
				<div
					className=""
					style={{ backgroundColor: "#ffffff", padding: "100px 30px" }}>
					<div className="dashboard-text">Super Admin Dashboard</div>
					<div id="admin" className="" style={{}}>
						<div className="">
							<div className="row format">
								<Sidenavbar />
								<div className="col-8" style={{}}>
									<div
										className="row format"
										style={{
											paddingTop: "20px",
											paddingBottom: "20px",
											backgroundColor: "#A7D588",
										}}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#24A2B3" }}>
											<div className="text1">{dlDetails.totalvideos}</div>
											<div className="text1">Total Videos</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#E96CB4" }}>
											<div className="text1">{dlDetails.totaldocuments}</div>
											<div className="text1">Total Documents</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#7F2DA2" }}>
											{" "}
											<div className="text1">{dlDetails.totalbooks}</div>
											<div className="text1">Total Books</div>
										</div>
									</div>
									{/* <div className="row format" style={{ paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#C78B62" }}>
											<div className="text1">200+</div>
											<div className="text1">Digital Library</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#4C87B8" }}>
											<div className="text1">200+</div>
											<div className="text1">Regulatory Compliances</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#8572D6" }}>
											{" "}
											<div className="text1">200+</div>
											<div className="text1">Placements</div>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoginRequest />
			)}
			<Footer />
		</>
	);
};
export default Digitallibrarymanagement;
