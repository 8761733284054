import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../components/Avatar";
import Carousel from "../components/Carousel";
import CardFooter from "../components/home/post_card/CardFooter";
import { getDataAPI } from "../utils/fetchData";
import Addpost from "./addpost";
import Footer from "./footer";
import Navbar from "./navbar";

export default function Group() {
	const { homePosts, auth } = useSelector((state) => state);
	const [posts, setPosts] = useState([]);

	useEffect(() => {
		getDataAPI(`posts/${window.location.pathname.split("/")[2]}`).then(
			(res) => {
				console.log("res getgroups", res.data);
				setPosts(res.data.posts);
			}
		);
	}, []);

	return (
		<>
			<Navbar />
			<div
				className="col-5"
				style={{
					marginRight: "10px",
					marginLeft: "80px",
					marginTop: "50px",
					justifySelf: "center",
				}}>
				<Addpost />
				<br />
				<div className="home row mx-0" style={{ padding: "0 0 0 56px" }}>
					<div className="col-md-8">
						{/* <Status />
								{homePosts.loading ? (
									<img
										src={LoadIcon}
										alt="loading"
										className="d-block mx-auto"
									/>
								) : homePosts.result === 0 ? (
									<h2 className="text-center">No Post</h2>
								) : ( */}
						{/* <Posts /> */}
						{/* )} */}
					</div>
				</div>

				{posts.length > 0 ? (
					posts.map((post) => (
						<div
							key={post.id}
							className="card row mb-3"
							style={{ borderRadius: "15px", border: "none" }}>
							<div
								className="row"
								style={{
									padding: "15px",
								}}>
								<div className="col-12">
									<div className="row">
										<div
											className="col-2"
											style={{
												width: "auto",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												paddingRight: "0px",
											}}>
											{/* <img
													alt="avatar"
													src={post.user.avatar}

													width="40px"
													height="40px"></img> */}
											<Avatar src={post.users.avatar} size="big-avatar" />
										</div>
										<div className="col-8">
											<div style={{ fontSize: "15px", fontWeight: "500" }}>
												{/* Dr. Sadiq Sait M.S */}
												{post.users.fullname}
											</div>
											<div style={{ fontSize: "12px", fontWeight: "500" }}>
												{/* 13 Feb 2024 at 10:32 am */}
												{moment(post.createdAt).fromNow()}
											</div>
										</div>
										<div
											className="col-2"
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "right",
											}}>
											{/* <OverlayTrigger
										trigger="click"
										key="bottom"
										placement="bottom"
										rootClose
										overlay={
											<Popover id={`popover-positioned-bottom`}>
												<Popover.Body>
													<div>
														<span>
															<img
																alt="hidepost"
																src="../images/Hide_post.svg"></img>
														</span>
														&nbsp; Hide Post
													</div>
													<div>
														<span>
															<img
																alt="reportpost"
																src="../images/Report_post.svg"></img>
														</span>
														&nbsp; Report Post
													</div>
												</Popover.Body>
											</Popover>
										}>
										<img
											src="../images/options.svg"
											alt=""
											style={{ cursor: "pointer" }}
										/>
									</OverlayTrigger> */}
										</div>
									</div>
								</div>
							</div>
							<div
								className="row"
								style={{
									padding: "0px 15px 5px 30px",
									fontSize: "14px",
									fontWeight: "400",
								}}>
								{/* Horticulture of Dasheri Mango */}
								{post.content}
							</div>
							<div className="row">
								{/* <img
										src="https://ulektzmumbai.sgp1.cdn.digitaloceanspaces.com/ulektzConnect/feeds/170961/images/54c3c4fe3401dbb2.jpeg"
										alt=""
									/> */}
								<Carousel images={post.images} id={post.id} />
							</div>
							<div
								className="row"
								style={{
									display: "flex",
									justifyContent: "space-between",
									padding: "10px 10px",
									//   borderBottom: "1px solid #ddd",
								}}>
								<div className="col-9" style={{ fontSize: "13px" }}>
									<img
										src="/images/Like-fill-icon.svg"
										alt=""
										width="20px"
										height="20px"></img>
									<span style={{ paddingLeft: "10px", fontSize: "13px" }}>
										{post.likes.length}
									</span>
								</div>
								<div
									className="col-3"
									style={{
										display: "flex",
										alignItems: "end",
										justifyContent: "end",
										fontSize: "13px",
										fontWeight: "400",
									}}>
									{post.comments.length} comments
								</div>
							</div>
							<hr style={{ marginBottom: "0px" }} />

							<CardFooter post={post} />

							{/* <div className="row" style={{ padding: "15px 15px" }}>
									<div
										className="col-6"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
										}}>
										<img alt="like" src="./images/Liked.svg" width="26px"></img>
										&nbsp;Like
									</div>
									<div
										className="col-6"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
										}}>
										<img src="./images/Comments.svg" width="26px"></img>&nbsp;
										Comments
									</div>
								</div> */}
						</div>
					))
				) : (
					<h1>No posts</h1>
				)}
			</div>
			<Footer />
		</>
	);
}
