import React from "react";

import {
	faCalendar,
	faLink,
	faPeopleGroup,
	faSuitcase,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Schedule = () => {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [allClasses, setAllClasses] = useState([]);
	useEffect(() => {
		const fetchAllClasses = async () => {
			try {
				const res = await getDataAPI("/getAllClasses", auth.token);
				setAllClasses(res.data.schedule_class);
			} catch (err) {
				console.error("Error fetching instructors:", err);
			}
		};

		if (auth.token) {
			fetchAllClasses();
		}
	}, [auth.token]);
	console.log(allClasses);

	const [formData, setFormData] = useState({
		name: "",
		organisationname: "",
		classtype: "",
		grouptype: "",
		dateandtime: null,
		noofmembers: "",
		course: "",
		instructor: "",
		proposal: "",
		listofmembers: "",
		URL: "",
	});
	const [selectedDate, setSelectedDate] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenInstructor, setIsOpenInstructor] = useState(false);
	const [selectedOption, setSelectedOption] = useState({
		coursetitle: "Select the Course",
	});
	const [fileUploaded, setFileUploaded] = useState("");
	const [instructorsList, setInstructorsList] = useState([]);
	const [selectedInstructorOption, setSelectedInstructorOption] = useState({
		name: "Select the Instructor",
	});
	const [coursesArray, setCoursesArray] = useState([]);

	const dropdownRef = useRef(null);
	const dropdownRef1 = useRef(null);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const toggleDropdownInstructor = () => {
		setIsOpenInstructor(!isOpenInstructor);
	};

	const handleOptionClick = (option) => {
		setSelectedOption(option);
		setIsOpen(false);
		setFormData((prevState) => ({
			...prevState,
			course: option,
		}));
	};

	const handleOptionClickInstructor = (option) => {
		setSelectedInstructorOption(option);
		setIsOpenInstructor(false);
		setFormData((prevState) => ({
			...prevState,
			instructor: option,
		}));
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target) &&
				dropdownRef1.current &&
				!dropdownRef1.current.contains(event.target)
			) {
				setIsOpen(false);
				setIsOpenInstructor(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleMembersUpload = (e) => {
		e.preventDefault();

		var files = e.target.files,
			f = files[0];
		var reader = new FileReader();
		reader.onload = function (e) {
			var data = e.target.result;

			let readedData = XLSX.read(data, { type: "binary" });
			const wsname = readedData.SheetNames[1];
			const ws = readedData.Sheets[wsname];

			const dataParse = XLSX.utils.sheet_to_json(ws, { header: 2 });
			const headers = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
			console.log("headers", headers);

			setFileUploaded(dataParse);
			setFormData((prev) => ({ ...prev, listofmembers: dataParse }));
		};
		reader.readAsBinaryString(f);
	};

	const handleProposalUpload = (e) => {
		e.preventDefault();
		var file = e.target.files[0];
		if (file) {
			setFormData((prevState) => ({
				...prevState,
				proposal: file,
			}));
		}
	};

	useEffect(() => {
		console.log("fileUploaded", fileUploaded);
	}, [fileUploaded]);

	useEffect(() => {
		if (auth.token) {
			getDataAPI(
				`getAllCoursesNames/${auth.user.id}/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
				auth.token
			).then((res) => {
				console.log("res my courses", res.data);
				setCoursesArray(res.data.courses);
			});
		}
	}, [auth]);

	useEffect(() => {
		if (selectedOption !== undefined) {
			console.log("selectedOption", selectedOption);
			getDataAPI(
				`getInstructorsNameForCourse/${selectedOption.id}`,
				auth.token
			).then((res) => {
				console.log("res my courses", res.data);
				setInstructorsList(res.data.instructors);
			});
		}
	}, [selectedOption]);

	const handleTypeChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			classtype: e.target.value,
		}));
	};

	const handleGroupTypeChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			grouptype: e.target.value,
		}));
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("formData", formData);
		const sendData = new FormData();
		if (formData.proposal !== "") {
			sendData.append("files", formData.proposal);
		}
		for (var key in formData) {
			sendData.append(
				key,
				typeof formData[key] === "object"
					? JSON.stringify(formData[key])
					: formData[key]
			);
		}
		try {
			const res = await postDataAPI(
				"createscheduleclass",
				sendData,
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				alert("Thank you for registering. We will get back to you soon");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="scclass">
				<div className="scclass-box">
					<div className="scclass-heading">
						<h2>Schedule a Class</h2>
					</div>
					<div className="scclass-boxIn">
						<div className="scclass-boxIn-content">
							<div className="scclass-greenbox">
								<div>
									<h4 className="scclass-greenbox-heading">Fill the form</h4>
								</div>
								<div>
									<form onSubmit={handleSubmit}>
										<div className="scclass-inputbox">
											<span className="scclass-inputbox-input">
												<input
													type="text"
													placeholder="Class Name"
													name="name"
													value={formData.name}
													onChange={handleInputChange}
												/>
											</span>
											<div className="scclass-Icon">
												<FontAwesomeIcon icon={faUser} />
											</div>
										</div>

										<div className="scclass-inputbox">
											<span className="scclass-inputbox-input">
												<input
													type="text"
													placeholder="Organisation Name"
													name="organisationname"
													value={formData.organisationname}
													onChange={handleInputChange}
												/>
											</span>
											<div className="scclass-Icon">
												<FontAwesomeIcon icon={faUser} />
											</div>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<div>
													<span style={{ fontSize: "16px" }}>Type: </span>
													<label style={{ marginLeft: "5px" }}>
														<input
															type="radio"
															value="Virtual"
															checked={formData.classtype === "Virtual"}
															onChange={handleTypeChange}
														/>{" "}
														Virtual
													</label>
													<label style={{ marginLeft: "5px" }}>
														<input
															type="radio"
															value="Onsite"
															checked={formData.classtype === "Onsite"}
															onChange={handleTypeChange}
														/>{" "}
														Onsite
													</label>
												</div>
											</span>
										</div>

										{formData.classtype === "Virtual" && (
											<div className="js-inputbox">
												<span className="js-inputbox-input">
													<div>
														<span style={{ fontSize: "16px" }}>Link: </span>
														<label style={{ marginLeft: "5px" }}>
															<input
																type="radio"
																value="Single"
																checked={formData.grouptype === "Single"}
																onChange={handleGroupTypeChange}
															/>{" "}
															Single
														</label>
														<label style={{ marginLeft: "5px" }}>
															<input
																type="radio"
																value="Multi"
																checked={formData.grouptype === "Multi"}
																onChange={handleGroupTypeChange}
															/>{" "}
															Multi
														</label>
													</div>
												</span>
											</div>
										)}
										<div className="scclass-inputbox">
											<span className="scclass-inputbox-input">
												<DatePicker
													showTimeSelect
													dateFormat="Pp"
													selected={formData.dateandtime}
													onChange={(date) =>
														setFormData((prev) => ({
															...prev,
															dateandtime: date,
														}))
													}
													// dateFormat="yyyy/MM/dd" // Customize the format if needed
													placeholderText="Date and Time"
												/>
											</span>
											<div className="scclass-Icon">
												<FontAwesomeIcon icon={faCalendar} />
											</div>
										</div>

										{/* <div className="scclass-inputbox">
											<span className="scclass-inputbox-input">
												<input type="text" placeholder="Time" />
											</span>
											<div className="scclass-Icon">
												<FontAwesomeIcon icon={faClock} />
											</div>
										</div> */}

										<div className="scclass-inputbox">
											<span className="scclass-inputbox-input">
												<input
													type="text"
													placeholder="No. of members"
													name="noofmembers"
													value={formData.noofmembers}
													onChange={handleInputChange}
												/>
											</span>
											<div className="scclass-Icon">
												<FontAwesomeIcon icon={faPeopleGroup} />
											</div>
										</div>

										<div className="scclass-inputbox">
											<span className="scclass-inputbox-input">
												<div
													className="scclass-dropdown-container"
													ref={dropdownRef}>
													<div className="scclass-dropdown">
														<div
															className="scclass-dropdown-header"
															onClick={toggleDropdown}>
															{selectedOption.coursetitle}
															<span
																className={`scclass-dropdown-symbol ${
																	isOpen ? "open" : ""
																}`}
															/>
														</div>

														{isOpen && (
															<ul className="scclass-dropdown-list">
																{coursesArray.map((option, index) => (
																	<li
																		key={index}
																		className="scclass-dropdown-item"
																		onClick={() => handleOptionClick(option)}>
																		{option.coursetitle}
																	</li>
																))}
															</ul>
														)}
													</div>
												</div>
											</span>
											<div className="scclass-Icon">
												<FontAwesomeIcon icon={faSuitcase} />
											</div>
										</div>

										<div className="scclass-inputbox">
											<span className="scclass-inputbox-input">
												<div
													className="scclass-dropdown-container"
													ref={dropdownRef1}>
													<div className="scclass-dropdown">
														<div
															className="scclass-dropdown-header"
															onClick={toggleDropdownInstructor}>
															{selectedInstructorOption.name}
															<span
																className={`scclass-dropdown-symbol ${
																	isOpenInstructor ? "open" : ""
																}`}
															/>
														</div>

														{isOpenInstructor && (
															<ul className="scclass-dropdown-list">
																{instructorsList.map((option, index) => (
																	<li
																		key={index}
																		className="scclass-dropdown-item"
																		onClick={() =>
																			handleOptionClickInstructor(option)
																		}>
																		{option.name}
																	</li>
																))}
															</ul>
														)}
													</div>
												</div>
											</span>
											<div className="scclass-Icon">
												<FontAwesomeIcon icon={faSuitcase} />
											</div>
										</div>

										<div className="scclass-inputbox">
											<span className="scclass-inputbox-input">
												<label>Proposal: </label>{" "}
												<input
													type="file"
													onChange={(e) => handleProposalUpload(e)}
													placeholder="Proposal"
												/>
											</span>
											<div className="scclass-Icon">
												<FontAwesomeIcon icon={faPeopleGroup} />
											</div>
										</div>

										<div className="scclass-inputbox">
											<span className="scclass-inputbox-input">
												<label>List of Members: </label>{" "}
												<input
													type="file"
													onChange={(e) => handleMembersUpload(e)}
													placeholder="List of members"
												/>
											</span>
											<div className="scclass-Icon">
												<FontAwesomeIcon icon={faPeopleGroup} />
											</div>
										</div>

										<div className="scclass-inputbox">
											<span className="scclass-inputbox-input">
												<input
													type="text"
													placeholder="Class room URL"
													name="URL"
													value={formData.URL}
													onChange={handleInputChange}
												/>
											</span>
											<div className="scclass-Icon">
												<FontAwesomeIcon icon={faLink} />
											</div>
										</div>
										<button type="submit" className="scclass-submitbtn">
											SUBMIT
										</button>
									</form>
								</div>
							</div>
						</div>
						<div className="scclass-boxIn-Image">
							<div className="scclass-boxIn-ImageIn">
								<img src="../images/Instructor-Led.png" alt="instructor" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Schedule;
