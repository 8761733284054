import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { postDataAPI } from "../utils/fetchData";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Listofinstructorcourses = () => {
	const location = useLocation();
	const { email } = location.state || {};
	const [courseIds, setCourseIds] = useState([]);

	useEffect(() => {
		const fetchInstructorId = async () => {
			try {
				const response = await postDataAPI("getInstructorByEmail", { email });

				if (response && response.data && Array.isArray(response.data.courses)) {
					const courses = response.data.courses.map((course) => {
						return {
							classId: course.classId,
							courseId: course.id,
							courseTitle: course.coursetitle,
							duration: course.duration,
							language: course.language_details
								? course.language_details.name
								: "N/A", // Use language name
						};
					});

					console.log("Extracted Courses:", courses);
					setCourseIds(courses);
				}
			} catch (error) {
				console.error("Error fetching instructor data:", error.message);
			}
		};

		if (email) {
			fetchInstructorId();
		}
	}, [email]);

	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="" style={{ padding: "100px" }}>
				<h4 className="instructorlist-text">List Of Instructor Courses</h4>

				{courseIds.length > 0 ? (
					courseIds.map((course, key) => (
						<div key={key} className="col-12" style={{ padding: "10px" }}>
							<div className="card" style={{ padding: "5px" }}>
								<div className="row">
									<div className="col-3" style={{ cursor: "pointer" }}>
										<img
											alt=""
											src="../images/Logo.png"
											style={{ width: "100%" }}
										/>
									</div>
									<div className="col-6 listofcourses-format">
										<div
											style={{
												fontSize: "20px",
												fontWeight: "500",
												minHeight: "36px",
											}}>
											Course Name: <label>{course.courseTitle}</label>
										</div>
										<div style={{ fontSize: "15px" }}>
											<div>
												Language: <label>{course.language}</label>
											</div>
											<div>
												Duration: <label>{course.duration} hrs</label>
											</div>
										</div>
									</div>
									<div className="col-3 listofcourses-format">
										{/* <a> */}
										<button
											onClick={() =>
												(window.location.href = "/attendance/" + course.classId)
											}
											className="addinstructor-btn"
											style={{
												background: "#F96342",
												border: "none",
												width: "150px",
											}}>
											Start
										</button>
										{/* </a> */}
									</div>
								</div>
							</div>
						</div>
					))
				) : (
					<p>No courses available for this instructor.</p>
				)}
			</div>
			<Footer />
		</>
	);
};

export default Listofinstructorcourses;
