import React from "react";
import { useSelector } from "react-redux";
import Adminnavbar from "./adminnavbar";
import Footer from "./footer";
import LoginRequest from "./loginRequest";
import Sidenavbar from "./sidenav";

const Superadmin = () => {
	const { profile, auth } = useSelector((state) => state);

	return (
		<>
			<Adminnavbar />

			{/* <Homecarousel /> */}
			{auth.user ? (
				<div
					className=""
					style={{ backgroundColor: "#ffffff", padding: "100px 30px" }}>
					<div className="dashboard-text">Super Admin Dashboard</div>
					<div id="admin" className="" style={{}}>
						<div className="">
							<div className="row format">
								<Sidenavbar />
								<div className="col-8" style={{ backgroundColor: "#A7D588" }}>
									<div
										className="row format"
										style={{ paddingTop: "20px", paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#24A2B3" }}>
											<div className="text1">200+</div>
											<div className="text1">Users</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#E96CB4" }}>
											<div className="text1">200+</div>
											<div className="text1">Instructor Led</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#7F2DA2" }}>
											{" "}
											<div className="text1">200+</div>
											<div className="text1">Courses</div>
										</div>
									</div>
									<div className="row format" style={{ paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#C78B62" }}>
											<div className="text1">200+</div>
											<div className="text1">Digital Library</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#4C87B8" }}>
											<div className="text1">200+</div>
											<div className="text1">Regulatory Compliances</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#8572D6" }}>
											{" "}
											<div className="text1">200+</div>
											<div className="text1">Placements</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoginRequest />
			)}
			<Footer />
		</>
	);
};
export default Superadmin;
