import moment from "moment";
import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import { useSelector } from "react-redux";
import { getDataAPI } from "../utils/fetchData";
import Adminnavbar from "./adminnavbar";
import Footer from "./footer";
import LoginRequest from "./loginRequest";
import Sidenavbar from "./sidenav";

const Classroommanagement = () => {
	const { profile, auth } = useSelector((state) => state);
	const [allClasses, setAllClasses] = useState([]);
	const [totalParticipants, setTotalParticipants] = useState(0);

	useEffect(() => {
		const fetchAllClasses = async () => {
			try {
				const res = await getDataAPI("getAllClasses", auth.token);
				console.log("res", res.data);
				setAllClasses(res.data.scheduleClasses);
				setTotalParticipants(res.data.totalparticipants);
			} catch (err) {
				console.error("Error fetching classes:", err);
			}
		};

		if (auth.token) {
			fetchAllClasses();
		}
	}, [auth.token]);

	const todayDate = moment().startOf("day");
	const previousDate = moment().subtract(1, "days").startOf("day");
	const upcomingDate = moment().add(1, "days").startOf("day");

	const getClassCategory = (classDate) => {
		const classMoment = moment(classDate, "DD/MM/YYYY h:mm A").startOf("day");

		if (classMoment.isSame(todayDate, "day")) {
			return "present";
		} else if (classMoment.isBefore(todayDate, "day")) {
			return "previous";
		} else if (classMoment.isAfter(todayDate, "day")) {
			return "upcoming";
		}
		return "";
	};
	console.log(allClasses);
	return (
		<>
			<Adminnavbar />
			{/* <Homecarousel /> */}
			{auth.user ? (
				<div
					className=""
					style={{ backgroundColor: "#ffffff", padding: "100px 30px" }}>
					<div className="button-format">
						<div className="dashboard-text">Super Admin Dashboard</div>
						<a href="/schedule-a-class">
							<button className="addinstructor-btn">+ SCHEDULE A CLASS</button>
						</a>
					</div>
					<div id="" className="" style={{}}>
						<div className="">
							<div className="row format">
								<Sidenavbar />
								<div
									className="col-8 card-format-analatics"
									style={{ backgroundColor: "#A7D588" }}>
									<div
										className="row format"
										style={{ paddingTop: "20px", paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#24A2B3" }}>
											<div className="text1">{allClasses.length}</div>
											<div className="text1">Total Classes</div>
										</div>
										<div
											className="col text-format"
											style={{ backgroundColor: "#E96CB4" }}>
											<div className="text1">
												{
													allClasses.filter(
														(scheduleClasses) =>
															getClassCategory(
																scheduleClasses.formattedDateTime
															) === "previous"
													).length
												}
											</div>
											<div className="text1">Previous Classes</div>
										</div>
										<div
											className="col text-format"
											style={{ backgroundColor: "#7F2DA2" }}>
											<div className="text1">
												{
													allClasses.filter(
														(scheduleClasses) =>
															getClassCategory(
																scheduleClasses.formattedDateTime
															) === "present"
													).length
												}
											</div>
											<div className="text1">Present Classes</div>
										</div>
									</div>
									<div className="row format" style={{ paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#C78B62" }}>
											<div className="text1">
												{
													allClasses.filter(
														(scheduleClasses) =>
															getClassCategory(
																scheduleClasses.formattedDateTime
															) === "upcoming"
													).length
												}
											</div>
											<div className="text1">Upcoming Classes</div>
										</div>
										<div
											className="col text-format"
											style={{ backgroundColor: "#4C87B8" }}>
											<div className="text1">{totalParticipants}</div>
											<div className="text1">Total Participants</div>
										</div>
										{/* <div
											className="col text-format"
											style={{ backgroundColor: "#8572D6" }}>
											<div className="text1">200+</div>
											<div className="text1">Placements</div>
										</div> */}
									</div>
								</div>
							</div>

							<div className="row" style={{ padding: "100px" }}>
								<h4 className="instructorlist-text">
									List Of Schedule Classes
								</h4>
								<div id="listclasses" className="col">
									<Tabs defaultActiveKey="second">
										<Tab eventKey="first" title="<<Previous Classes">
											<div className="" style={{ paddingTop: "50px" }}>
												<Table>
													<thead id="pl">
														<tr>
															<th>S.No</th>
															<th>Class Name</th>
															<th>Date and Time</th>
															<th>Course Name</th>
															<th>List Of Numbers</th>
															<th>Class URL</th>
														</tr>
													</thead>

													{allClasses && allClasses.length > 0 ? (
														allClasses
															.filter(
																(scheduleClasses) =>
																	getClassCategory(
																		scheduleClasses.formattedDateTime
																	) === "previous"
															)
															.map((scheduleClasses, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>

																	<td>{scheduleClasses.className}</td>
																	<td>{scheduleClasses.formattedDateTime}</td>
																	<td>{scheduleClasses.courseName}</td>
																	<td>{scheduleClasses.noOfMembers}</td>

																	<td>
																		<a href={scheduleClasses.classURL}>
																			{scheduleClasses.classURL}
																		</a>
																	</td>
																</tr>
															))
													) : (
														<tr>
															<td colSpan="7">
																No previous classes available at the moment.
															</td>
														</tr>
													)}
												</Table>
											</div>
										</Tab>

										<Tab eventKey="second" title="Present Classes">
											<div className="" style={{ paddingTop: "50px" }}>
												<Table>
													<thead id="pl">
														<tr>
															<th>S.No</th>
															<th>Class Name</th>
															<th>Date and Time</th>
															<th>Course Name</th>
															<th>List Of Numbers</th>
															<th>Class URL</th>
														</tr>
													</thead>

													{/* Filter and show only present classes */}
													{allClasses && allClasses.length > 0 ? (
														allClasses
															.filter(
																(scheduleClasses) =>
																	getClassCategory(
																		scheduleClasses.formattedDateTime
																	) === "present"
															)
															.map((scheduleClasses, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{scheduleClasses.className}</td>
																	<td>{scheduleClasses.formattedDateTime}</td>
																	<td>{scheduleClasses.courseName}</td>
																	<td>{scheduleClasses.noOfMembers}</td>
																	<td>
																		<a href={scheduleClasses.classURL}>
																			{scheduleClasses.classURL}
																		</a>
																	</td>
																</tr>
															))
													) : (
														<tr>
															<td colSpan="7">
																No present classes available at the moment.
															</td>
														</tr>
													)}
												</Table>
											</div>
										</Tab>

										<Tab eventKey="third" title="Upcoming Classes>>">
											<div className="" style={{ paddingTop: "50px" }}>
												<Table>
													<thead id="pl">
														<tr>
															<th>S.No</th>
															<th>Class Name</th>
															<th>Date and Time</th>
															<th>Course Name</th>
															<th>List Of Numbers</th>
															<th>Class URL</th>
														</tr>
													</thead>

													{/* Filter and show only upcoming classes */}
													{allClasses && allClasses.length > 0 ? (
														allClasses
															.filter(
																(scheduleClasses) =>
																	getClassCategory(
																		scheduleClasses.formattedDateTime
																	) === "upcoming"
															)
															.map((scheduleClasses, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{scheduleClasses.className}</td>
																	<td>{scheduleClasses.formattedDateTime}</td>
																	<td>{scheduleClasses.courseName}</td>
																	<td>{scheduleClasses.noOfMembers}</td>
																	<td>
																		<a href={scheduleClasses.classURL}>
																			{scheduleClasses.classURL}
																		</a>
																	</td>
																</tr>
															))
													) : (
														<tr>
															<td colSpan="7">
																No upcoming classes available at the moment.
															</td>
														</tr>
													)}
												</Table>
											</div>
										</Tab>
									</Tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoginRequest />
			)}
			<Footer />
		</>
	);
};
export default Classroommanagement;
