// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import "../Menu.css";
// import Footer from "./footer";
// import Navbar from "./navbar";
// import Homecarousel from "./homecarousel";
// import { Modal } from "react-bootstrap";

// const items = [
//   {
//     id: 1,
//     name: "Srinivas",
//     email: "srinivas@gmail.com",
//     course: "course1",
//   },
//   {
//     id: 2,
//     name: "raju",
//     email: "raju@gmail.com",
//     course: "course2",
//   },
//   {
//     id: 3,
//     name: "Radha",
//     email: "radha@gmail.com",
//     course: "course1",
//   },
//   {
//     id: 4,
//     name: "Ashwini",
//     email: "ashwini@gmail.com",
//     course: "course1",
//   },
//   {
//     id: 5,
//     name: "Shiva",
//     email: "shiva@gmail.com",
//     course: "course1",
//   },
// ];

// const Attendence = () => {
//   // State to track checkbox values for "Select" and "In Stock" columns

//   const [selectedItems, setSelectedItems] = useState(
//     items.reduce((acc, item) => ({ ...acc, [item.id]: false }), {})
//   );

//   const [inStockItems, setInStockItems] = useState(
//     items.reduce((acc, item) => ({ ...acc, [item.id]: false }), {})
//   );

//   // Toggle individual checkbox for "Select" column
//   const handleSelectChange = (id) => {
//     setSelectedItems((prevState) => ({
//       ...prevState,
//       [id]: !prevState[id],
//     }));
//     console.log({selectedItems})
//   };

//   // Toggle individual checkbox for "In Stock" column
//   const handleInStockChange = (id) => {
//     setInStockItems((prevState) => ({
//       ...prevState,
//       [id]: !prevState[id],
//     }));
//      console.log({ inStockItems });
//   };

//   return (
// 		<>
// 			<div className="att" style={{ padding: "0px 100px" }}>
// 				<div className="att-box">
// 					<div className="att-heading h2">
// 						<h2>Attendance</h2>
// 					</div>
// 					<div className="att-table">
// 						<table style={{ width: "100%", tableLayout: "fixed" }}>
// 							<thead>
// 								<tr
// 									style={{
// 										backgroundColor: "rgb(167, 213, 136)",
// 										fontSize: "15px",
// 										fontWeight: "700",
// 										letterSpacing: "0.5px",
// 									}}>
// 									<th style={{}}>
// 										<p
// 											style={{
// 												textAlign: "center",
// 												paddingTop: "10px",
// 												fontFamily: "Rubik, sans-serif",
// 											}}>
// 											<strong>Name</strong>
// 										</p>
// 									</th>
// 									<th style={{}}>
// 										<p
// 											style={{
// 												textAlign: "center",
// 												paddingTop: "10px",
// 												fontFamily: "Rubik, sans-serif",
// 											}}>
// 											<strong>Email</strong>
// 										</p>
// 									</th>
// 									<th>
// 										<p
// 											style={{
// 												textAlign: "center",
// 												paddingTop: "10px",
// 												fontFamily: "Rubik, sans-serif",
// 											}}>
// 											<strong>Course</strong>
// 										</p>
// 									</th>
// 									<th>
// 										<p style={{ textAlign: "center", paddingTop: "10px" }}>
// 											<strong>Start Session</strong>
// 										</p>
// 									</th>
// 									<th>
// 										<p style={{ textAlign: "center", paddingTop: "10px" }}>
// 											<strong>End Session</strong>
// 										</p>
// 									</th>
// 								</tr>
// 							</thead>
// 							<tbody>
// 								{items.map((item, index) => (
// 									<tr
// 										key={index}
// 										style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
// 										<td>
// 											<p
// 												style={{
// 													textAlign: "center",
// 													paddingTop: "20px",
// 													fontFamily: "Rubik, sans-serif",
// 												}}>
// 												{item.name}
// 											</p>
// 										</td>
// 										<td>
// 											<p
// 												style={{
// 													textAlign: "center",
// 													paddingTop: "20px",
// 													fontFamily: "Rubik, sans-serif",
// 												}}>
// 												{item.email}
// 											</p>
// 										</td>
// 										<td>
// 											<p
// 												style={{
// 													textAlign: "center",
// 													paddingTop: "20px",
// 													fontFamily: "Rubik, sans-serif",
// 												}}>
// 												{item.course}
// 											</p>
// 										</td>
// 										<td>
// 											<p
// 												style={{
// 													textAlign: "center",
// 													paddingTop: "20px",
// 												}}>
// 												<input
// 													type="checkbox"
// 													checked={selectedItems[item.id]}
// 													onChange={() => handleSelectChange(item.id)}
// 												/>
// 											</p>
// 										</td>
// 										<td>
// 											<p style={{ textAlign: "center", paddingTop: "10px" }}>
// 												<input
// 													type="checkbox"
// 													checked={inStockItems[item.id]}
// 													onChange={() => handleInStockChange(item.id)}
// 												/>
// 											</p>
// 										</td>
// 									</tr>
// 								))}
// 							</tbody>
// 						</table>
// 					</div>
// 					<div className="att-submitlnk-btn">
// 						<button
// 							name="submit"
// 							className="att-submitlnk"
// 							onClick={() => {
// 								handleSelectChange();
// 								handleInStockChange();
// 							}}>
// 							Submit
// 						</button>
// 						<a href="www.google.com" target="_blank">
// 							<buttton
// 								className="att-submitlnk1"
// 								onClick={() => {

// 								}}>
// 								Go to Session
// 							</buttton>
// 						</a>
// 					</div>
// 				</div>
// 			</div>
// 		</>
// 	);
// };

// const styles = {
//   evenRow: {
//     backgroundColor: "#f9f9f9",
//   },
//   oddRow: {
//     backgroundColor: "#e9e9e9", // Slightly darker color for odd rows
//   },
// };

// export default Attendence;

//-----------------------------------
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getDataAPI, postDataAPI } from "../utils/fetchData";

const Attendence = () => {
	const location = useLocation();

	const { profile, auth } = useSelector((state) => state);
	const [allClasses, setAllClasses] = useState("");
	const [selectedItems, setSelectedItems] = useState({}); // State for start session checkboxes
	const [inStockItems, setInStockItems] = useState({}); // State for end session checkboxes
	const [members, setMembers] = useState([]);
	// Fetch all classes from API
	useEffect(() => {
		const fetchAllClasses = async () => {
			try {
				const res = await getDataAPI(
					`getScheduleClassById/${window.location.pathname.split("/")[2]}`,
					localStorage.getItem("access_token")
				);
				console.log("res getScheduleClassById", res.data);
				setAllClasses(res.data.scheduleclass);
				setMembers(JSON.parse(res.data.scheduleclass.listofmembers));
			} catch (err) {
				console.error("Error fetching classes:", err);
			}
		};

		// if (auth.token) {
		fetchAllClasses();
		// }
	}, []);

	// Toggle function for "Start Session" checkbox
	const handleSelectChangeStart = (e, id, index) => {
		console.log("index", index, e.target.checked);
		// setSelectedItems((prevState) => ({
		// 	...prevState,
		// 	[id]: !prevState[id], // Toggle the checkbox state for the specific ID
		// }));
		members[index] = { ...members[index], start: e.target.checked };
		setAllClasses((prevState) => ({
			...prevState,
			listofmembers: JSON.stringify(members),
		}));
		// setMembers((prev) => [])
	};

	const handleSelectChangeEnd = (e, id, index) => {
		console.log("index", index);
		// setSelectedItems((prevState) => ({
		// 	...prevState,
		// 	[id]: !prevState[id], // Toggle the checkbox state for the specific ID
		// }));
		members[index] = { ...members[index], end: e.target.checked };
		setAllClasses((prevState) => ({
			...prevState,
			listofmembers: JSON.stringify(members),
		}));
		// setMembers((prev) => [])
	};

	useEffect(() => {
		console.log("members", members);
	}, [members]);

	// Toggle function for "End Session" checkbox
	const handleInStockChange = (id) => {
		setInStockItems((prevState) => ({
			...prevState,
			[id]: !prevState[id], // Toggle the checkbox state for the specific ID
		}));
	};

	const todayDate = moment().startOf("day");

	// Function to determine the category of the class (present, previous, upcoming)
	const getClassCategory = (classDate) => {
		const classMoment = moment(classDate, "DD/MM/YYYY h:mm A").startOf("day");

		if (classMoment.isSame(todayDate, "day")) {
			return "present";
		} else if (classMoment.isBefore(todayDate, "day")) {
			return "previous";
		} else if (classMoment.isAfter(todayDate, "day")) {
			return "upcoming";
		}
		return "";
	};

	const handleSessionClick = () => {
		let id = JSON.parse(allClasses.course).id;
		window.open("/pdfreader/" + id, "_blank");
		window.open(allClasses.URL, "_blank");
	};

	const handleSubmitClick = async () => {
		const sendData = new FormData();
		sendData.append("id", window.location.pathname.split("/")[2]);
		sendData.append("listofmembers", JSON.stringify(members));
		// for (var key in allClasses) {
		// 	sendData.append(
		// 		key,
		// 		typeof allClasses[key] === "object"
		// 			? JSON.stringify(allClasses[key])
		// 			: allClasses[key]
		// 	);
		// }
		try {
			const res = await postDataAPI(
				`updatescheduleclass`,
				sendData,
				localStorage.getItem("access_token")
			);
			const res1 = await postDataAPI(
				`classcomplete`,
				sendData,
				localStorage.getItem("access_token")
			);
			console.log("res update allclasses", res.data, res1.data);
		} catch (err) {
			console.error("Error fetching classes:", err);
		}
	};

	return (
		<>
			<div className="att" style={{ padding: "0px 100px" }}>
				<div className="att-box">
					<div className="att-heading h2">
						<h2>Attendance</h2>
					</div>
					<div className="att-table">
						<table style={{ width: "100%", tableLayout: "fixed" }}>
							<thead>
								<tr
									style={{
										backgroundColor: "rgb(167, 213, 136)",
										fontSize: "15px",
										fontWeight: "700",
										letterSpacing: "0.5px",
									}}>
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Name</strong>
										</p>
									</th>
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Email</strong>
										</p>
									</th>
									{/* <th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Course</strong>
										</p>
									</th> */}
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Start Session</strong>
										</p>
									</th>
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>End Session</strong>
										</p>
									</th>
								</tr>
							</thead>
							<tbody>
								{allClasses !== "" ? (
									members.map((scheduleClasses, index) => (
										<tr
											key={index}
											style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
											<td style={{ textAlign: "center", paddingTop: "10px" }}>
												{scheduleClasses.Name}
											</td>
											<td style={{ textAlign: "center", paddingTop: "10px" }}>
												{scheduleClasses.Email}
											</td>
											{/* <td>{scheduleClasses.courseName}</td> */}
											<td style={{ textAlign: "center", paddingTop: "10px" }}>
												<input
													type="checkbox"
													// checked={selectedItems[scheduleClasses.id] || false} // Ensure the checkbox state is based on `selectedItems`
													checked={
														scheduleClasses.start === true ? true : false
													}
													onClick={(e) =>
														handleSelectChangeStart(
															e,
															scheduleClasses.id,
															index
														)
													}
												/>
											</td>
											<td style={{ textAlign: "center", paddingTop: "10px" }}>
												<input
													type="checkbox"
													// checked={inStockItems[scheduleClasses.id] || false} // Ensure the checkbox state is based on `inStockItems`
													// onChange={() =>
													// 	handleInStockChange(scheduleClasses.id)
													// }
													checked={scheduleClasses.end === true ? true : false}
													onClick={(e) =>
														handleSelectChangeEnd(e, scheduleClasses.id, index)
													}
												/>
											</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan="5">
											No present classes available at the moment.
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
					<div className="att-submitlnk-btn">
						<button
							name="submit"
							className="att-submitlnk"
							onClick={() => {
								console.log("Selected Items: ", members);
								handleSubmitClick();
								// console.log("In Stock Items: ", inStockItems);
							}}>
							Submit
						</button>
						{/* <a href={allClasses.URL} target="_blank" rel="noopener noreferrer"> */}
						<button
							onClick={() => handleSessionClick()}
							className="att-submitlnk1">
							Go to Session
						</button>
						{/* </a> */}
					</div>
				</div>
			</div>
		</>
	);
};

const styles = {
	evenRow: {
		backgroundColor: "#f9f9f9",
	},
	oddRow: {
		backgroundColor: "#e9e9e9", // Slightly darker color for odd rows
	},
};

export default Attendence;
