import {
	faAddressBook,
	faEnvelope,
	faMapMarkerAlt,
	faPaperPlane,
	faPhone,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const ContactUs = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />

			<div className="row blockcs">
				<div className="col-1"></div>
				<div className="col-3">
					<div
						className="info-item"
						style={{ marginTop: "0px", padding: "30px" }}>
						<div className="cs-icon1">
							<FontAwesomeIcon className="cs-icon2" icon={faAddressBook} />
						</div>
						<span>
							<b>Phone Number</b> <br />
							111-123123
						</span>
					</div>
					<div
						className="info-item"
						style={{ marginTop: "40px", padding: "30px" }}>
						<div className="cs-icon1">
							<FontAwesomeIcon className="cs-icon2" icon={faEnvelope} />
						</div>
						<span>
							<b>Email</b> <br />
							info@krishigap.com
						</span>
					</div>
					<div
						className="info-item"
						style={{ marginTop: "40px", padding: "30px" }}>
						<div className="cs-icon1">
							<FontAwesomeIcon className="cs-icon2" icon={faMapMarkerAlt} />
						</div>

						<span>
							<b>Location</b>
							<br /> Krishigap Digital Solutions Pvt ltd CIN
							U62013TS2023PTC172865 House No 5-106/281B, Narsing Municipality
							Manchirevula K.V Ranga Reddy Hyderabad 500075 Telangana State
						</span>
					</div>
				</div>
				<div className="col-7">
					<iframe
						title="Location"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d673.0029880514054!2d78.44098872170552!3d17.407956990232616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb974870d9c515%3A0x7629611b65cffb6f!2sEFresh%20Agribusiness%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1732272534795!5m2!1sen!2sin"
						width="760px"
						height="450px"
						style={{ border: 0 }}
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					/>
				</div>

				<div className="col-1"></div>
			</div>
			<div className="row">
				<div className="col-1"></div>
				<div className="col-10 cs-form">
					<h2
						style={{
							color: "rgb(21, 34, 44)",
							fontFamily: "Rajdhani, sans-serif",
							fontSize: "30px",
							fontWeight: "700",
						}}>
						We Can take your business to growth Up
					</h2>
					<p
						style={{
							marginTop: "20px",
							fontFamily: "Rubik, sans-serif",
							fontSize: "16px",
							color: "rgb(97,102,102)",
							fontWeight: "400",
							marginBottom: "20px",
						}}>
						Constimes section description here
					</p>
					<form className="contact-form">
						<div className="form-group input-box">
							<input
								type="text"
								id="name"
								name="name"
								placeholder="Your Name"
							/>
							<FontAwesomeIcon
								style={{ width: "70px" }}
								className="cs-icon"
								icon={faUser}
							/>
						</div>
						<div className="form-group input-box">
							<input
								type="email"
								id="email"
								name="email"
								placeholder="Your Email"
							/>
							<FontAwesomeIcon
								style={{ width: "70px" }}
								className="cs-icon"
								icon={faPaperPlane}
							/>
						</div>
						<div className="form-group input-box">
							<input
								type="tel"
								id="mobile"
								name="mobile"
								placeholder="Phone Number"
							/>
							<FontAwesomeIcon
								style={{ width: "70px" }}
								className="cs-icon"
								icon={faPhone}
							/>
						</div>
						<div className="form-group input-box">
							<textarea
								id="message"
								name="message"
								rows="4"
								placeholder="Message"></textarea>
							<FontAwesomeIcon
								style={{ width: "70px" }}
								className="cs-icon top-right"
								icon={faEnvelope}
							/>
						</div>
						<button type="submit" className="hover-button">
							SUBMIT
						</button>
					</form>
				</div>
				<div className="col-1"></div>
			</div>
			<Footer />
		</>
	);
};

export default ContactUs;
