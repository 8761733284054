import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive1 = {
	desktop: {
		breakpoint: {
			max: 3000,
			min: 1400,
		},
		items: 1,
		partialVisibilityGutter: 40,
	},
	desktop1: {
		breakpoint: {
			max: 1400,
			min: 1024,
		},
		items: 1,
		partialVisibilityGutter: 40,
	},
	mobile: {
		breakpoint: {
			max: 767,
			min: 0,
		},
		items: 1,
		partialVisibilityGutter: 30,
	},
	tablet: {
		breakpoint: {
			max: 1024,
			min: 767,
		},
		items: 1,
		partialVisibilityGutter: 30,
	},
};

const Homecarousel = () => {
	return (
    <div className="" style={{}}>
      <Carousel
        responsive={responsive1}
        arrows={true}
        autoPlay
        infinite
        slidesToSlide={1}
      >
        <div style={{ height: "550px" }}>
          <div
            className="bg"
            style={{
              backgroundImage: "url('../images/banner1-1.jpg')",
              // opacity: 0.7,
              // filter: "contrast(.7) brightness(.7)",
              height: "550px",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="row bgon-text">
              <div className="col-5" style={{}}>
                <div
                  style={{
                    opacity: "",
                    backgroundColor: "RGBA(114,177,77,0.68)",
                  }}
                >
                  <div className="sectionimg-text">
                    {" "}
                    Building Skills in Sustainability Standards to Combat
                    Climate Change and Carbon Credits
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "550px" }}>
          <div
            className="bg"
            style={{
              backgroundImage: "url('../images/banner2-1.jpg')",
              // filter: "contrast(.7) brightness(.7)",
              height: "550px",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="row ">
              <div className="col-5 " style={{}}>
                <div
                  className="bgon-text"
                  style={{
                    opacity: "",
                    backgroundColor: "RGBA(114,177,77,0.68)",
                  }}
                >
                  <div className="sectionimg-text">
                    {" "}
                    Promoting Good Agricultural Practices for Safer, <br />
                    Quality Food and a Greener Future.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: "550px" }}>
          <div
            className="bg"
            style={{
              backgroundImage: "url('../images/banner3-1.jpg')",
              height: "550px",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="row ">
              <div className="col-5" style={{}}>
                <div
                  className="bgon-text"
                  style={{
                    opacity: "",
                    backgroundColor: "RGBA(114,177,77,0.68)",
                  }}
                >
                  <div className=" sectionimg-text">
                    Enhancing Food Safety Standards in <br /> Processing for
                    Safe and Quality Food.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "550px" }}>
          <div
            className="bg"
            style={{
              backgroundImage: "url('../images/banner4-2.jpg')",
              height: "550px",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="row ">
              <div className="col-5" style={{}}>
                <div
                  className="bgon-text"
                  style={{
                    opacity: "",
                    backgroundColor: "RGBA(114,177,77,0.68)",
                  }}
                >
                  <div className="sectionimg-text">
                    Digital Hub for Videos and Documents <br />
                    to Simplify Implementation
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "550px" }}>
          <div
            className="bg"
            style={{
              backgroundImage: "url('../images/banner5-2.jpg')",
              height: "550px",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="row ">
              <div className="col-5" style={{}}>
                <div
                  className="bgon-text"
                  style={{
                    opacity: "",
                    backgroundColor: "RGBA(114,177,77,0.68)",
                  }}
                >
                  <div className=" sectionimg-text">
                    Model Farms : Integrating Food Safety, <br />
                    Sustainability Standards and Smart Farming for <br />
                    Streamlined Compliance.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "550px" }}>
          <div
            className="bg"
            style={{
              backgroundImage: "url('../images/banner6-2.jpg')",
              height: "550px",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="row ">
              <div className="col-5" style={{}}>
                <div
                  className="bgon-text"
                  style={{
                    opacity: "",
                    backgroundColor: "RGBA(114,177,77,0.68)",
                  }}
                >
                  <div className="sectionimg-text">
                    Guide to Regulatory Compliances.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Homecarousel;
