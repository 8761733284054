import React from "react";
import { Link } from "react-router-dom";
import "../Menu.css";
import Homecarousel from "./homecarousel";
import Footer from "./footer";
import Navbar from "./navbar";

const JobPlacement = () => {
  return (
    <>
    <Navbar/><Homecarousel/>
      <div className="jb">
        <div className="jb-box">
          <div className="jb-container">
            <div className="jb-content">
              <div className="jb-content-text">
                <h2 className="jb-content-heading">Choose who you are</h2>
              </div>
              <div className="jb-content-button">
                <Link to="/job-seeker">
                  <button className="jb-content-button-In" type="button">
                    Job Seeker
                  </button>
                </Link>
              </div>
              <div className="jb-content-button">
                <Link to="/employer">
                  <button className="jb-content-button-In" type="button">
                    Employer
                  </button>
                </Link>
              </div>
            </div>
            <div className="jb-Image">
              <img src="../images/jp.png" alt="Image" />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default JobPlacement;
