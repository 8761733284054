import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../Menu.css";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF } from "../utils/fetchData";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const RegulatoryCompliances = () => {
	const [complience, SetComplience] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		getDataAPI(`getregulatories`).then((res) => {
			console.log("res getregulatories", res.data);
			SetComplience(res.data.regulatories);
		});
	}, []);

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			window.open(fileURL);
			// setViewChange(false);
			// setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};

	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="rc">
				<div className="rc-heading">
					<h2>Overview</h2>
				</div>
				<div className="rc-box">
					<div className="rc-box1">
						<h6 style={{ marginBottom: "15px" }}>
							Regulatory Compliance for Global Food Safety and Sustainability
							Standards
						</h6>
						<p>
							Ensuring compliance across the entire food chain is crucial for
							the implementation of the standards. Adherence to both domestic
							and international regulations (destination countries) enable
							seamless trade and reduces associated risks. While we provide
							regulatory information to the best of our knowledge, we advise
							users to conduct their own due diligence to ensure complete
							compliance.
						</p>

						<h6 style={{ marginTop: "20px", marginBottom: "20px" }}>
							Key Benefits:
						</h6>

						<ul style={{ margin: "0px", padding: "0px" }}>
							<li>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										<b>Increased Awareness:</b> Clear insights into regulatory
										requirements minimize the risks of non-compliance, legal
										issues, and penalties.
									</span>
								</div>
							</li>
							<li style={{ margin: "0px" }}>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										<b>Streamlined Processes:</b> Easy access to compliance
										guidelines simplifies licensing, certification, and quality
										inspections, enhancing operational efficiency.
									</span>
								</div>
							</li>
							<li style={{ margin: "0px" }}>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										<b>Cost and Time Savings:</b> Consolidated and up-to-date
										information helps users save resources and make informed
										decisions.
									</span>
								</div>
							</li>
							<li style={{ margin: "0px" }}>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										<b>Improved Market Access: </b> Understanding export and
										import requirements boosts opportunities to enter new
										markets.
									</span>
								</div>
							</li>
						</ul>
						<p style={{ marginBottom: "10px" }}>
							We empower users to confidently navigate the complex regulatory
							landscape.
						</p>
					</div>
					<div className="rc-box2">
						<h6>Subjects</h6>
						<ul style={{ margin: "0px", padding: "0px" }}>
							{complience.map((val, index) => {
								return (
									<li
										onClick={() => {
											fileConversion(val.source);
										}}>
										<div className="rc-list">
											<div class="rc-icon">
												<svg
													aria-hidden="true"
													class="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>
											</div>
											<span class="rc-text">{val.name}</span>
										</div>
									</li>
								);
							})}

							{/* <li>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										Permitted Plant Protection Products for Use
									</span>
								</div>
							</li>
							<li>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										Importance of Regulatory Compliance for Food Imports
									</span>
								</div>
							</li>
							<li>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										Regulatory Compliances for food export from India
									</span>
								</div>
							</li>
							<li>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										Regulatory Compliances for food import from India
									</span>
								</div>
							</li>
							<li>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										Regulatory Compliances for food importation into India
									</span>
								</div>
							</li>
							<li>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										Regulatory Compliances for Organic food export from India
									</span>
								</div>
							</li>
							<li>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										Establishing Food Business in India
									</span>
								</div>
							</li>
							<li>
								<div className="rc-list">
									<div class="rc-icon">
										<svg
											aria-hidden="true"
											class="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span class="rc-text">
										Testing in Accredited Labs under International Standard ISO
										17025
									</span>
								</div>
							</li> */}
						</ul>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default RegulatoryCompliances;
