import React, { useEffect, useState } from "react";
// import "../Menu.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { adminLogin, login } from "../redux/actions/authAction";
import Footer from "./footer";
import Navbar from "./navbar";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash, FaRegBell } from "react-icons/fa";
const Login = () => {
  const initialState = { email: "", password: "" };
  const [userData, setUserData] = useState(initialState);
  const [userType, setUserType] = useState(false);
  const { email, password } = userData;

  const [typePass, setTypePass] = useState(false);

  const { auth } = useSelector((state) => state);
   const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  // useEffect(() => {
  //   if (auth.token) history.push("/");
  // }, [auth.token, history]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userType) {
      dispatch(login(userData));
    } else {
      dispatch(adminLogin(userData));
    }
  };

  return (
    <>
      <Navbar />
      {/* <div className="" style={{ backgroundColor: "#fff" }}>
        <div class="spacer"></div>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 block">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <label className="email-label">Username or Email address</label>
                <div className="email-input">
                  <input
                    className="email-input"
                    type="email"
                    onChange={handleChangeInput}
                    value={email}
                    name="email"
                  />
                </div>
              </div>
              <div class="spacer"></div>
              <div className="row">
                <label className="password-label">Password</label>
                <div className="password-input">
                  <input
                    className="password-input"
                    type="password"
                    onChange={handleChangeInput}
                    value={password}
                    name="password"
                  />
                </div>
              </div>
              <div class="spacer"></div>
              <div className="form-group">
                <input className="checkbox" type="checkbox" />
                <label>Remember Me</label>
              </div>
              <div class="spacer"></div>
              <button className="login-button" type="submit">
                Login
              </button>
              <div class="spacer"></div>
              <br />
              <button className="Lost-button" type="submit">
                Join Now
              </button>
              {"|"}
              <button className="Lost-button" type="submit">
                Lost Password
              </button>
            </form>
          </div>
          <div className="col-1"></div>
        </div>
        <div class="spacer"></div>
      </div> */}
      <div className="" style={{backgroundColor:"white"}}>
        <div class="spacer"></div>
        <div id="testlogin">
          <div className="row">
            <div className="col-1"></div>
            <div className="col-10 block">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <label className="email-label">
                    Username or Email address
                  </label>
                  <div className="email-input">
                    <input
                      className="email-input"
                      type="email"
                      onChange={handleChangeInput}
                      value={email}
                      name="email"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <label className="password-label">Password</label>
                    <span
                      className="toggle-password d-flex align-items-center"
                      onClick={() => {
                        setShowPassword(!showPassword);
                        // handleClick;
                      }}
                    >
                      {showPassword ? (
                        <>
                          <FaEyeSlash className="me-1" />
                          Hide Password
                        </>
                      ) : (
                        <>
                          <FaEye className="me-1" />
                          Show Password
                        </>
                      )}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="password-input">
                    <input
                      className="password-input"
                      type={showPassword ? "text" : "password"}
                      onChange={handleChangeInput}
                      value={password}
                      name="password"
                    />
                  </div>
                </div>

                {/* <div className="checkkbox">
                  <input className="checkbox" type="checkbox" />
                  <label>Remember Me</label>
                </div> */}

                <button className="login-button mt-3" type="submit">
                  Log In
                </button>
              </form>
            </div>

            <div className="col-1"></div>
          </div>
          <div className="row">
            <div className="col-1"></div>
            <div className="col-10 text-left join">
              <Link to="/register">
                <button className="Lost-button" type="button">
                  Join Now
                </button>
              </Link>
              {" | "}
              <Link to="/lostpassword">
                <button className="Lost-button-2" type="button">
                  Lost Password
                </button>
              </Link>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
        <div class="spacer"></div>
      </div>
      <Footer />
    </>
  );
};
export default Login;
