import React from "react";
import "../Menu.css";
import AdvisoryBoard from "./advisoryboard";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const OurTeam = () => {
	return (
		<div>
			<Navbar />
			<Homecarousel />

			<div className="page-gap">
				<div className="our-team-container-root">
					<div className="our-team-container animated fadeInUp">
						<div className="widget-container">
							<p className="mb-4">
								The Company’s leadership team is supported by an experienced
								Advisory Board and domain experts specializing in:
							</p>
							<ul className="list" style={{ listStyleType: "initial" }}>
								<li>Global Standards in Food Safety and Sustainability.</li>
								<li>Product Development.</li>
								<li>Organic, Dairy, and Aquaculture Value Chains.</li>
								<li>Information Technology.</li>
								<li>Finance.</li>
							</ul>
						</div>
					</div>

					<div
						className="founder-title-container"
						style={{ paddingTop: "50px" }}>
						<p>Founders</p>
					</div>

					<div className="team-container row">
						<div className="col-12 col-sm-6 col-md-4">
							<div className="team-member">
								<div className="team-member-img-container">
									<a href="./sriharikotela">
										<img src="..\images\shri-hari.jpg" alt="Srihari Kotela" />
									</a>
								</div>
								<div className="team-member-details-container">
									<div className="team-member-details">
										<div className="team-member-details-left">
											<a href="./sriharikotela">
												{" "}
												<div className="person-name">Srihari Kotela</div>
											</a>
											<div className="person-designation">Founder</div>
										</div>
										<div className="team-member-details-right">
											<div
												className="fade-up-button"
												style={{ borderRadius: "8px" }}>
												<i
													className="fas fa-share"
													style={{ color: "white", marginRight: "5px" }}></i>
											</div>
											<div className="all-social-media-fade-up-button">
												<div
													className="fade-up-button"
													style={{
														borderTopLeftRadius: "8px",
														borderTopRightRadius: "8px",
													}}>
													<i
														className="fab fa-facebook"
														style={{ color: "white", marginRight: "5px" }}></i>
												</div>
												<div className="fade-up-button">
													<i
														className="fab fa-twitter"
														style={{ color: "white", marginRight: "5px" }}></i>
												</div>
												<div className="fade-up-button">
													<i
														className="fab fa-instagram"
														style={{ color: "white", marginRight: "5px" }}></i>
												</div>
												<div className="fade-up-button">
													<i
														className="fab fa-pinterest"
														style={{ color: "white", marginRight: "5px" }}></i>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-sm-6 col-md-4">
							<div
								className="team-member"
								style={{ display: "flex", justifyContent: "end" }}>
								<div className="team-member-img-container">
									<a href="./surbhiagarwal">
										<img
											src="..\images\Surbhi_Agarwal - Copy.jpg"
											alt="Surbhi Agarwal"
										/>
									</a>
								</div>
								<div className="team-member-details-container">
									<div className="team-member-details">
										<div className="team-member-details-left">
											<a href="./surbhiagarwal">
												{" "}
												<div className="person-name">Surbhi Agarwal</div>
											</a>
											<div className="person-designation">Co-Founder</div>
										</div>
										<div className="team-member-details-right">
											<div
												className="fade-up-button"
												style={{ borderRadius: "8px" }}>
												<i
													className="fas fa-share"
													style={{ color: "white", marginRight: "5px" }}></i>
											</div>
											<div className="all-social-media-fade-up-button">
												<div
													className="fade-up-button"
													style={{
														borderTopLeftRadius: "8px",
														borderTopRightRadius: "8px",
													}}>
													<i
														className="fab fa-facebook"
														style={{ color: "white", marginRight: "5px" }}></i>
												</div>
												<div className="fade-up-button">
													<i
														className="fab fa-twitter"
														style={{ color: "white", marginRight: "5px" }}></i>
												</div>
												<div className="fade-up-button">
													<i
														className="fab fa-instagram"
														style={{ color: "white", marginRight: "5px" }}></i>
												</div>
												<div className="fade-up-button">
													<i
														className="fab fa-pinterest"
														style={{ color: "white", marginRight: "5px" }}></i>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<AdvisoryBoard />
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default OurTeam;
