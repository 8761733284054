import React from "react";
import "../Menu.css"; // Ensure your custom styles are applied here
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const KeyObjectives = () => {
	return (
		<div>
			<Navbar />
			<div className="" style={{}}>
				<Homecarousel />
			</div>
			<div className="page-gap">
				<div className="ko-container">
					<div className="e-con-inner">
						{/* Left Section: Key Objectives */}
						<div className="left-section">
							<h2 className="elementor-heading-title">Key Objectives</h2>
							<div className="objectives-list">
								<div className="objective-item fadeInUp">
									<p>
										<strong>Building Skilled Professionals:</strong> Developing
										talent through digital tools and Model Farms to lead the
										agriculture sector.
									</p>
								</div>
								<div className="objective-item fadeInUp">
									<p>
										<strong>Boosting Farmers and Processors Earnings:</strong>{" "}
										Facilitation of compliance with standards ensures better
										prices and market access.
									</p>
								</div>
								<div className="objective-item fadeInUp">
									<p>
										<strong>Ensuring Food Safety:</strong> Implementation of
										standards guarantee safe food for all and foster market
										growth.
									</p>
								</div>
								<div className="objective-item fadeInUp">
									<p>
										<strong>Promoting Climate-Resilience:</strong> Sustainable
										practices mitigate climate impacts and ensure long-term
										productivity.
									</p>
								</div>
								<div className="objective-item fadeInUp">
									<p>
										<strong>Enabling Carbon Market Access:</strong> Facilitation
										of compliance with standards unlocks revenue opportunities
										through carbon trading.
									</p>
								</div>
								<div className="objective-item fadeInUp">
									<p>
										<strong>Reducing Non-Conformities:</strong> Structured
										support enhances compliance and builds trust in global
										markets.
									</p>
								</div>
							</div>
						</div>

						{/* Right Section: Vision and Mission */}
						<div className="right-section">
							{/* Vision */}
							<div className="vision">
								<div className="icon">
									<i aria-hidden="true" className="fa fa-eye"></i>
								</div>
								<div className="text">
									<h3>Vision</h3>
									<p>
										To build an integrated ecosystem that empowers the food
										supply chain to achieve global food safety and
										sustainability standards compliance, fostering economic and
										environmental well-being.
									</p>
								</div>
							</div>

							{/* Mission */}
							<div className="mission">
								<div className="icon">
									<i aria-hidden="true" className="fas fa-atom"></i>
								</div>
								<div className="text">
									<h3>Mission</h3>
									<p>
										To deliver an AI-powered platform with virtual and
										instructor-led training, complemented by Model Farms, to
										empower stakeholders with best practices for global food
										safety and sustainability compliance.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default KeyObjectives;
