import React from "react";
import { useSelector } from "react-redux";

const LikeButton = ({ isLike, handleLike, handleUnLike }) => {
	const { theme } = useSelector((state) => state);
	return (
		<div
			className="col-6"
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				cursor: "pointer",
			}}>
			{isLike ? (
				// <i
				//   className="fas fa-thumbs-up text-info"
				//   style={{ filter: theme ? "invert(1)" : "invert(0)" }}
				//   onClick={handleUnLike}
				// />
				<div onClick={handleUnLike}>
					<img alt="like" src="/images/Liked.svg" width="26px"></img>
					&nbsp;Liked
				</div>
			) : (
				// <i className="far fa-thumbs-up" onClick={handleLike} />
				<div onClick={handleLike}>
					<img alt="like" src="/images/Like.svg" width="26px"></img>
					&nbsp;Like
				</div>
			)}
		</div>
	);
};

export default LikeButton;
