import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../Menu.css"; // Import the custom CSS
import { logout } from "../redux/actions/authAction";

const Navbar = () => {
	const { auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(null);

	const toggleMobileMenu = () => {
		setMobileMenuOpen(!mobileMenuOpen);
	};

	const toggleDropdown = (index) => {
		setDropdownOpen(dropdownOpen === index ? null : index);
	};

	return (
		<div className="menu-bar v2">
			{/* Menu Bar Start */}
			<div>
				<div className="">
					<div className="menu-bar-content">
						{/* Logo Section */}
						<div className="menu-logo">
							<a href="/">
								<img
									href="/"
									src="../images/LOGO.png"
									alt="logo"
									height={100}
								/>
							</a>

							<div className="logo-text-container">
								<span className="logo-divider"></span>
								<a
									className="logo-text nav-links"
									href="/"
									style={{ padding: "0" }}>
									GLOBAL ACADEMY <br /> ON FOOD SAFETY AND <br />
									SUSTAINABLE STANDARDS
								</a>
							</div>
						</div>

						{/* Navigation Menu */}
						<nav className="main-menu">
							<ul className="menu-list">
								<li className="menu-item nav-item">
									<a title="Home" href="/home" className="nav-links">
										Home
									</a>
								</li>

								{/* About Us Dropdown */}
								<li className="menu-item menu-item-has-children nav-item">
									<a
										title="About Us"
										href="#"
										className="nav-links"
										aria-expanded={dropdownOpen === 1}
										onClick={(e) => {
											e.preventDefault();
											toggleDropdown(1);
										}}>
										About Us
										<span className="dropdown-arrow"></span>
									</a>
									{dropdownOpen === 1 && (
										<ul className="submenu">
											<li className="menu-item">
												<a
													title="Key Objectives"
													href="/keyobjectives"
													className="dropdown-items">
													Key Objectives
												</a>
											</li>
											<li className="menu-item">
												<a
													title="Company Profile"
													href="/companyprofile"
													className="dropdown-items">
													Company Profile
												</a>
											</li>
											<li className="menu-item">
												<a
													title="Our Team"
													href="/ourteam"
													className="dropdown-items">
													Our Team
												</a>
											</li>
											<li className="menu-item">
												<a
													title="Experts"
													href="/experts"
													className="dropdown-items">
													Experts
												</a>
											</li>
										</ul>
									)}
								</li>
								<li className="menu-item menu-item-has-children nav-item">
									<a
										title="What We Do"
										href="#"
										className="nav-links"
										aria-expanded={dropdownOpen === 2}
										onClick={(e) => {
											e.preventDefault();
											toggleDropdown(2);
										}}>
										What We Do
										<span className="dropdown-arrow"></span>
									</a>
									{dropdownOpen === 2 && (
										<ul className="submenu">
											<li className="menu-item">
												<a
													title="Problem Statement"
													href="/problemstatement"
													className="dropdown-items">
													Problem Statement
												</a>
											</li>
											<li className="menu-item">
												<a
													title="Our Approach"
													href="/ourapproach"
													className="dropdown-items">
													Our Approach
												</a>
											</li>
											<li className="menu-item">
												<a
													title="Platform Features"
													href="/platformfeatures"
													className="dropdown-items">
													Platform Features
												</a>
											</li>
											<li className="menu-item">
												<a
													title="Standards Coverage"
													href="/standardscoverage"
													className="dropdown-items">
													Standards Coverage
												</a>
											</li>
											<li className="menu-item">
												<a
													title="Target Audience"
													href="/targetaudience"
													className="dropdown-items">
													Target Audience
												</a>
											</li>
											<li className="menu-item">
												<a
													title="Impact Creation"
													href="/impactcreation"
													className="dropdown-items">
													Impact Creation
												</a>
											</li>
										</ul>
									)}
								</li>

								<li className="menu-item nav-item">
									<a title="AgriGrowth" href="/modelfarm" className="nav-links">
										AgriGrowth
									</a>
								</li>
								<li className="menu-item nav-item">
									<a
										title="agripreneurs"
										href="/agripreneurs"
										className="nav-links">
										Agripreneurs
									</a>
								</li>
								<li className="menu-item nav-item">
									<a title="students" href="/students" className="nav-links">
										Students
									</a>
								</li>

								{/* What We Do Dropdown */}

								{/* FAQ's and Contact Us Links */}
								<li className="menu-item nav-item">
									<a title="FAQ's" href="/faqs" className="nav-links">
										FAQ’s
									</a>
								</li>
								<li className="menu-item nav-item">
									<a title="Contact Us" href="/contactus" className="nav-links">
										Contact Us
									</a>
								</li>
								<li className="menu-item nav-item">
									{auth.user === undefined ? (
										<a title="Log In" href="/login" className="nav-links">
											<i
												class="fa fa-user"
												aria-hidden="true"
												style={{ marginRight: "8px" }}></i>
											Log In
										</a>
									) : (
										<>
											<a
												title="Logout"
												href="/login"
												onClick={() => dispatch(logout())}
												className="nav-links">
												<i
													class="fa fa-sign-out"
													aria-hidden="true"
													style={{ marginRight: "8px" }}></i>
												Logout
											</a>
										</>
									)}
								</li>
							</ul>
						</nav>

						{/* Mobile Menu Button */}
						<button
							className="mobile-menu-btn"
							onClick={toggleMobileMenu}
							aria-label="Toggle mobile menu">
							<span></span>
							<span></span>
							<span></span>
						</button>

						{/* Mobile Menu */}
						{mobileMenuOpen && (
							<div className="mobile-menu">
								<div className="mobile-header">
									<div className="mobile-logo">
										<a
											className="standard-logo"
											href="https://digitalvega.in/foodsafetyacademy/">
											<img
												src="https://digitalvega.in/foodsafetyacademy/wp-content/uploads/2024/10/main-logo-1.png"
												alt="logo"
											/>
										</a>
									</div>
									<button
										className="close-mobile-btn"
										onClick={toggleMobileMenu}>
										<span className="my-icon icon-angle-arrow-left"></span>
									</button>
								</div>
								<div className="mobile-overflow">
									<nav className="main-manu">
										<ul id="menu-our-menu">
											<li className="menu-item nav-item">
												<a
													title="Home"
													href="https://digitalvega.in/foodsafetyacademy/home/"
													className="nav-links">
													Home
												</a>
											</li>
											{/* Mobile Dropdown Items... */}
										</ul>
									</nav>
								</div>
							</div>
						)}

						{/* Mobile Menu Overlay */}
						{mobileMenuOpen && (
							<div
								className="mobile-menu-overlay"
								onClick={toggleMobileMenu}></div>
						)}
					</div>
				</div>
			</div>
			{/* Menu Bar End */}
		</div>
	);
};

export default Navbar;
