import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDataAPI } from "../utils/fetchData";
import Footer from "./footer";
import Navbar from "./navbar";

const Listofjobseeker = () => {
	const [alljobseekers, setAlljobseekers] = useState([]);
	const { auth } = useSelector((state) => state);
	const history = useHistory();

	// Fetch job seekers on component mount
	console.log("history.location.state", history.location.state?.email);

	useEffect(() => {
		const fetchAlljobseekers = async () => {
			try {
				console.log(auth.token);
				const res = await getDataAPI("getAllAcceptedJobseekers");

				setAlljobseekers(res.data.jobseekers); // Adjust based on API response structure
			} catch (err) {
				console.error("Error fetching job seekers:", err);
			}
		};
		if (history.location.state?.email) {
			fetchAlljobseekers();
		} else {
			alert("Please login to view this page");
			window.location.href = "./employerlogin";
		}
	}, [auth.token, history]);

	return (
		<>
			<Navbar />
			{/* <Homecarousel /> */}
			<div className="container" style={{ padding: "100px" }}>
				<h4 className="instructorlist-text">List Of Job Seekers</h4>
				{alljobseekers.map((jobseeker) => (
					<div
						key={jobseeker.id}
						className="col-12"
						style={{ padding: "10px" }}>
						<div className="card" style={{ padding: "5px" }}>
							<div className="row">
								<div className="col-3" style={{ cursor: "pointer" }}>
									<img
										alt=""
										src={jobseeker.profilepic || "../images/common-user.svg"}
										style={{ width: "100%" }}
									/>
								</div>
								<div className="col-6 listofcourses-format">
									<div
										style={{
											fontSize: "20px",
											fontWeight: "500",
											minHeight: "36px",
										}}>
										Name: <label>{jobseeker.name}</label>
									</div>
									<div style={{ fontSize: "15px" }}>
										<div>
											Food Safety Standard Intrested:{" "}
											<b>{jobseeker.f_s_s_intersted}</b>
										</div>
										<div>
											Availability: <b>{jobseeker.availability}</b>
										</div>
									</div>
								</div>
								<div className="col-3 listofcourses-format">
									<a href={`/jobseekerprofile/${jobseeker.id}`}>
										<button
											className="addinstructor-btn"
											style={{
												background: "rgb(30 243 164)",
												border: "none",
												width: "150px",
											}}>
											View Profile
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			<Footer />
		</>
	);
};

export default Listofjobseeker;
