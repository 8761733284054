import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import "../Menu.css";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import "./communitystyles.css";
import Footer from "./footer";
import ImageCropper from "./imagecroppopup";
import Navbar from "./navbar";

const Content1 = () => (
	<div className="db-content-cnr">
		<h1
			style={{
				fontFamily: "Rajdhnai,sans-serif",
				fontSize: "24px",
				fontWeight: "700",
			}}>
			Posts
		</h1>
		<p>
			<b>Name :</b> XYZ
		</p>
		<p>
			<b>Post Content :</b> Catering
		</p>

		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
			}}>
			<img
				src="../images/agripreneurs3.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
			<img
				src="../images/agripreneurs1.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
		</div>
		<br />
		<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
			<div className="db-content-button">
				<span
					class="fas fa-comment elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>

				<a href="#">Comment</a>
			</div>
			<div className="db-content-button" style={{ color: "green" }}>
				<span
					class="fas fa-thumbs-up elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#" style={{ color: "green" }}>
					Like
				</a>
			</div>
			<div className="db-content-button">
				<span
					class="fas fa-share elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#">Share</a>
			</div>
		</div>
	</div>
);

const Content2 = () => (
	<div className="db-content-cnr">
		<h1
			style={{
				fontFamily: "Rajdhnai,sans-serif",
				fontSize: "24px",
				fontWeight: "700",
			}}>
			Posts
		</h1>
		<p>
			<b>Name :</b> XYZ
		</p>
		<p>
			<b>Post Content :</b> 2
		</p>

		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
			}}>
			<img
				src="../images/agripreneurs3.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
			<img
				src="../images/agripreneurs1.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
		</div>
		<br />
		<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
			<div className="db-content-button">
				<span
					class="fas fa-comment elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>

				<a href="#">Comment</a>
			</div>
			<div className="db-content-button" style={{ color: "green" }}>
				<span
					class="fas fa-thumbs-up elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#" style={{ color: "green" }}>
					Like
				</a>
			</div>
			<div className="db-content-button">
				<span
					class="fas fa-share elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#">Share</a>
			</div>
		</div>
	</div>
);

const Content3 = () => (
	<div className="db-content-cnr">
		<h1
			style={{
				fontFamily: "Rajdhnai,sans-serif",
				fontSize: "24px",
				fontWeight: "700",
			}}>
			Posts
		</h1>
		<p>
			<b>Name :</b> XYZ
		</p>
		<p>
			<b>Post Content :</b> 3
		</p>

		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
			}}>
			<img
				src="../images/agripreneurs3.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
			<img
				src="../images/agripreneurs1.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
		</div>
		<br />
		<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
			<div className="db-content-button">
				<span
					class="fas fa-comment elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>

				<a href="#">Comment</a>
			</div>
			<div className="db-content-button" style={{ color: "green" }}>
				<span
					class="fas fa-thumbs-up elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#" style={{ color: "green" }}>
					Like
				</a>
			</div>
			<div className="db-content-button">
				<span
					class="fas fa-share elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#">Share</a>
			</div>
		</div>
	</div>
);

const Content4 = () => (
	<div className="db-content-cnr">
		<h1
			style={{
				fontFamily: "Rajdhnai,sans-serif",
				fontSize: "24px",
				fontWeight: "700",
			}}>
			Posts
		</h1>
		<p>
			<b>Name :</b> XYZ
		</p>
		<p>
			<b>Post Content :</b> 4
		</p>

		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
			}}>
			<img
				src="../images/agripreneurs3.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
			<img
				src="../images/agripreneurs1.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
		</div>
		<br />
		<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
			<div className="db-content-button">
				<span
					class="fas fa-comment elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>

				<a href="#">Comment</a>
			</div>
			<div className="db-content-button" style={{ color: "green" }}>
				<span
					class="fas fa-thumbs-up elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#" style={{ color: "green" }}>
					Like
				</a>
			</div>
			<div className="db-content-button">
				<span
					class="fas fa-share elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#">Share</a>
			</div>
		</div>
	</div>
);

const Content5 = () => (
	<div className="db-content-cnr">
		<h1
			style={{
				fontFamily: "Rajdhnai,sans-serif",
				fontSize: "24px",
				fontWeight: "700",
			}}>
			Posts
		</h1>
		<p>
			<b>Name :</b> XYZ
		</p>
		<p>
			<b>Post Content :</b> 5
		</p>

		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
			}}>
			<img
				src="../images/agripreneurs3.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
			<img
				src="../images/agripreneurs1.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
		</div>
		<br />
		<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
			<div className="db-content-button">
				<span
					class="fas fa-comment elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>

				<a href="#">Comment</a>
			</div>
			<div className="db-content-button" style={{ color: "green" }}>
				<span
					class="fas fa-thumbs-up elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#" style={{ color: "green" }}>
					Like
				</a>
			</div>
			<div className="db-content-button">
				<span
					class="fas fa-share elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#">Share</a>
			</div>
		</div>
	</div>
);

const Content6 = () => (
	<div className="db-content-cnr">
		<h1
			style={{
				fontFamily: "Rajdhnai,sans-serif",
				fontSize: "24px",
				fontWeight: "700",
			}}>
			Posts
		</h1>
		<p>
			<b>Name :</b> XYZ
		</p>
		<p>
			<b>Post Content :</b> 6
		</p>

		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
			}}>
			<img
				src="../images/agripreneurs3.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
			<img
				src="../images/agripreneurs1.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
		</div>
		<br />
		<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
			<div className="db-content-button">
				<span
					class="fas fa-comment elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>

				<a href="#">Comment</a>
			</div>
			<div className="db-content-button" style={{ color: "green" }}>
				<span
					class="fas fa-thumbs-up elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#" style={{ color: "green" }}>
					Like
				</a>
			</div>
			<div className="db-content-button">
				<span
					class="fas fa-share elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#">Share</a>
			</div>
		</div>
	</div>
);

const Content7 = () => (
	<div className="db-content-cnr">
		<h1
			style={{
				fontFamily: "Rajdhnai,sans-serif",
				fontSize: "24px",
				fontWeight: "700",
			}}>
			Posts
		</h1>
		<p>
			<b>Name :</b> XYZ
		</p>
		<p>
			<b>Post Content :</b> 7
		</p>

		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
			}}>
			<img
				src="../images/agripreneurs3.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
			<img
				src="../images/agripreneurs1.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
		</div>
		<br />
		<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
			<div className="db-content-button">
				<span
					class="fas fa-comment elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>

				<a href="#">Comment</a>
			</div>
			<div className="db-content-button" style={{ color: "green" }}>
				<span
					class="fas fa-thumbs-up elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#" style={{ color: "green" }}>
					Like
				</a>
			</div>
			<div className="db-content-button">
				<span
					class="fas fa-share elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#">Share</a>
			</div>
		</div>
	</div>
);

const Content8 = () => (
	<div className="db-content-cnr">
		<h1
			style={{
				fontFamily: "Rajdhnai,sans-serif",
				fontSize: "24px",
				fontWeight: "700",
			}}>
			Posts
		</h1>
		<p>
			<b>Name :</b> XYZ
		</p>
		<p>
			<b>Post Content :</b> 8
		</p>

		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
			}}>
			<img
				src="../images/agripreneurs3.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
			<img
				src="../images/agripreneurs1.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
		</div>
		<br />
		<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
			<div className="db-content-button">
				<span
					class="fas fa-comment elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>

				<a href="#">Comment</a>
			</div>
			<div className="db-content-button" style={{ color: "green" }}>
				<span
					class="fas fa-thumbs-up elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#" style={{ color: "green" }}>
					Like
				</a>
			</div>
			<div className="db-content-button">
				<span
					class="fas fa-share elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#">Share</a>
			</div>
		</div>
	</div>
);

const Content9 = () => (
	<div className="db-content-cnr">
		<h1
			style={{
				fontFamily: "Rajdhnai,sans-serif",
				fontSize: "24px",
				fontWeight: "700",
			}}>
			Posts
		</h1>
		<p>
			<b>Name :</b> XYZ
		</p>
		<p>
			<b>Post Content :</b> 9
		</p>

		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
			}}>
			<img
				src="../images/agripreneurs3.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
			<img
				src="../images/agripreneurs1.jpg"
				alt="agripreneurs"
				width="470"
				height="313"
				style={{
					borderRadius: "5px",
				}}
			/>
		</div>
		<br />
		<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
			<div className="db-content-button">
				<span
					class="fas fa-comment elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>

				<a href="#">Comment</a>
			</div>
			<div className="db-content-button" style={{ color: "green" }}>
				<span
					class="fas fa-thumbs-up elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#" style={{ color: "green" }}>
					Like
				</a>
			</div>
			<div className="db-content-button">
				<span
					class="fas fa-share elementskit-tab-icon"
					style={{ marginRight: "10px" }}></span>
				<a href="#">Share</a>
			</div>
		</div>
	</div>
);

const Discussionboard = () => {
	const { profile, auth } = useSelector((state) => state);

	const [selectedContent, setSelectedContent] = useState(1); // Default to first link content
	const [showJoinModal, setShowJoinModal] = useState(false);
	const [showProfilePhotoModal, setShowProfilePhotoModal] = useState(false);
	const [imageToCropProfilePhoto, setImageToCropProfilePhoto] =
		useState(undefined);
	const [croppedImageProfilePhoto, setCroppedImageProfilePhoto] =
		useState(undefined);
	const [size, setSize] = useState("");
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [email, setEmail] = useState("");
	const [mobile, setMobile] = useState("");
	const [whatsapp, setWhatsapp] = useState("");
	const [topicsOfInterest, setTopicsOfInterest] = useState("");
	const [areaOfExpertise, setAreaOfExpertise] = useState("");
	const [yearsOfExperience, setYearsOfExperience] = useState("");
	const [foodSafetyStandard, setFoodSafetyStandard] = useState("");
	const [preferredNotification, setPreferredNotification] = useState("");
	const [termsAndPrivacy, setTermsAndPrivacy] = useState(false);
	const [profilePicture, setProfilePicture] = useState(null);
	const inputFileProfilePhoto = useRef(null);
	const onButtonClickProfilePhoto = () => {
		// `current` points to the mounted file input element
		inputFileProfilePhoto.current.click();
	};
	const [groups, setGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState({});
	const [userRole, setUserRole] = useState("user");

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	const handleLinkClick = (contentKey) => {
		setSelectedContent(contentKey);
	};

	const handleCloseJoinModal = () => {
		setShowJoinModal(false);
	};

	const handleOpenjoinModal = (e) => {
		setShowJoinModal(true);
		setSelectedGroup(e);
	};

	const handleOpenprofilePhotoModal = () => {
		setShowProfilePhotoModal(true);
		setShowJoinModal(false);
	};

	const handleCloseProfilePhotoModal = () => {
		setShowProfilePhotoModal(false);
		setShowJoinModal(true);
	};

	const onUploadFileProfilePhoto = (event) => {
		let sizeInBytes = event.target.files[0].size;
		var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setSize(sizeInMB + "MB");

		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const image = reader.result;
				// console.log(image);
				// setGetUpload(image);
				setImageToCropProfilePhoto(image);
			});
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	useEffect(() => {
		if (auth.user !== undefined) {
			getDataAPI(
				`getAllGroupAdminsByUserId/${auth?.user?.id}`,
				auth.token
			).then((res) => {
				console.log("res getgroups", res.data);
				if (res.data.groupAdmins.id !== undefined) {
					window.location.href = "/group/" + res.data.groupAdmins.id;
				}
			});

			getDataAPI(
				`getcommunityuser/${auth.user.id}/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
				auth.token
			).then((res) => {
				console.log("res community user", res.data);
				setUserRole(res.data.communityuser.role);
			});
		}
	}, [auth]);

	useEffect(() => {
		getDataAPI(`getAllGroups`, auth.token).then((res) => {
			console.log("res getgroups", res.data);
			setGroups(res.data.groups);
		});
	}, [auth.token]);

	const renderContent = () => {
		switch (selectedContent) {
			case 1:
				return <Content1 />;
			case 2:
				return <Content2 />;
			case 3:
				return <Content3 />;
			case 4:
				return <Content4 />;
			case 5:
				return <Content5 />;
			case 6:
				return <Content6 />;
			case 7:
				return <Content7 />;
			case 8:
				return <Content8 />;
			case 9:
				return <Content9 />;
			default:
				return <p>No content available.</p>;
		}
	};
	const handleInputChange = (setter) => (event) => setter(event.target.value);

	const handleGenderChange = (event) => setGender(event.target.value);

	const handleFileChange = (event) => setProfilePicture(event.target.files[0]);

	const handleTermsChange = () => setTermsAndPrivacy(!termsAndPrivacy);
	const handleSubmit = async (event) => {
		event.preventDefault();

		console.log("Form Data:");
		console.log("Full Name:", name);
		console.log("Gender:", gender);
		console.log("Email:", email);
		console.log("Mobile:", mobile);
		console.log("WhatsApp:", whatsapp);
		console.log("Topics of Interest:", topicsOfInterest);
		console.log("Area of Expertise:", areaOfExpertise);
		console.log("Years of Experience:", yearsOfExperience);
		console.log("Food Safety Standard:", foodSafetyStandard);
		console.log("Preferred Notification:", preferredNotification);
		console.log("Terms and Privacy Agreed:", termsAndPrivacy);
		if (profilePicture) {
			console.log("Profile Picture:", profilePicture.name);
		}

		try {
			const res = await postDataAPI("creategroupAdmins", {
				name,
				groupid: selectedGroup.id,
				userid: auth.user.id,
				gender,
				email,
				mobile,
				whatsapp,
				topics_of_interest: topicsOfInterest,
				expertise: areaOfExpertise,
				experience: yearsOfExperience,
				f_s_s_intersted: foodSafetyStandard,
				preferred_notification: preferredNotification,
			});
			console.log("res", res);
			if (res.status === 200) {
				alert("Thank you for creating this group");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			// dispatch({
			// 	type: GLOBALTYPES.ALERT,
			// 	payload: { error: err.response.data.msg },
			// });
		}
	};
	return (
		<>
			<Modal
				show={showProfilePhotoModal}
				onHide={handleCloseProfilePhotoModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Profile Photo
							</h2>
						</div>
						<div className="col-1" style={{ padding: "15px" }}>
							<img src="./images/closeOne.png"></img>
						</div>
					</div>
					<hr />
					{imageToCropProfilePhoto ? (
						<ImageCropper
							imageToCrop={imageToCropProfilePhoto}
							onImageCropped={(croppedImage) => {
								console.log(croppedImage);
								setCroppedImageProfilePhoto(croppedImage);
							}}
							coverphoto="coverphoto"
						/>
					) : (
						<div
							style={{
								backgroundColor: "#efefef",
								height: "50vh",
								width: "34vw",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<img src="./images/profile.svg" style={{ height: "90px" }}></img>
						</div>
					)}

					<div
						style={{
							fontSize: "13px",
							fontWeight: "300",
							textAlign: "center",
							paddingTop: "10px",
						}}>
						Recommended size - 1920 x 1080px, Max. 2 MB
					</div>
					<div
						className="row"
						style={{ backgroundColor: "#efefef", padding: "10px 10px" }}>
						<div className="col-8">
							<input
								type="file"
								id="file"
								ref={inputFileProfilePhoto}
								style={{ display: "none" }}
								onChange={onUploadFileProfilePhoto}
							/>
							<button
								onClick={onButtonClickProfilePhoto}
								style={{
									background: "#fff",
									border: "none",
									padding: "5px",
									borderRadius: "5px",
								}}>
								<img
									src="https://www.ulektz.com/assets/img/home/camera.svg"
									alt=""
								/>{" "}
								<span style={{ fontSize: "12px" }}>Select Photo</span>
							</button>
							{/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
						</div>
						<div className="col-2">
							<div>
								<Button
									style={{
										background: "#fff",
										border: "none",
										height: "35px",
										color: "#000",
										border: "1px solid #000",
										borderRadius: "15px",
									}}
									onClick={handleCloseProfilePhotoModal}>
									Cancel
								</Button>
							</div>
						</div>
						<div className="col-2">
							<div>
								<Button
									style={{
										background: "#3CCE8F",
										border: "none",
										height: "35px",
										//   width:"40px",
										borderRadius: "15px",
										//   display:"flex",
										//   alignItems:"center",
										//   justifyContent:"end"
									}}>
									Upload
								</Button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showJoinModal}
				onHide={handleCloseJoinModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11" style={{ paddingLeft: "0px" }}>
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
									marginBottom: "0px",
									display: "flex",
								}}>
								Join - {selectedGroup.name} &nbsp;
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseJoinModal}>
							<img src="./images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
					{/* <div
						class="uc-pro-left-img"
						style={{ padding: "20px 40px", textAlign: "center" }}>
						{croppedImageProfilePhoto === undefined ? (
							<img
								src="./images/krishigaplogo.png"
								id="createComprofileimg"
								alt=""
								class="uc-profile-images clsProfileImg"
								style={{
									height: "112px",
									width: "112px",
									borderRadius: "50%",
									border: "1px solid #ddd",
								}}
							/>
						) : (
							<img
								src={URL.createObjectURL(croppedImageProfilePhoto)}
								id="createComprofileimg"
								alt=""
								class="uc-profile-images clsProfileImg"
								style={{
									height: "112px",
									width: "112px",
									borderRadius: "50%",
									border: "1px solid #ddd",
								}}
							/>
						)}

						<label class="uc-cam-icon" onclick="openCreateComProf_UpPop()">
							<img
								id="cameraicon"
								src="https://www.ulektz.com/assets/img/home/camera.svg"
								alt=""
								style={{
									backgroundColor: "#E4E3E0",
									marginLeft: "-30px",
									padding: "6px 6px",
									borderRadius: "40%",
									marginTop: "75px",
								}}
								onClick={handleOpenprofilePhotoModal}
							/>
						</label>
					</div> */}
					<div style={{ padding: "15px 45px 10px" }}>
						<form onSubmit={handleSubmit}>
							{/* Full Name */}
							<label htmlFor="name" style={{ paddingBottom: "5px" }}>
								Full Name*
							</label>
							<input
								type="text"
								className="form-control"
								value={name}
								onChange={handleInputChange(setName)}
								placeholder="Enter Full Name"
							/>

							{/* Gender */}
							<label
								htmlFor="gender"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Gender
							</label>
							<div className="d-flex">
								<div className="form-group-inputs form-check me-2">
									<input
										type="radio"
										id="male"
										name="gender"
										value="Male"
										checked={gender === "Male"}
										onChange={handleGenderChange}
										className="form-check-input"
									/>

									<label className="form-check-label" htmlFor="male">
										Male
									</label>
								</div>
								<div className="form-group-inputs form-check">
									<input
										type="radio"
										id="female"
										name="gender"
										value="Female"
										checked={gender === "Female"}
										onChange={handleGenderChange}
										className="form-check-input"
									/>
									<label className="form-check-label" htmlFor="female">
										Female
									</label>
								</div>
							</div>
							{/* Profile Picture */}
							{/* <label
								htmlFor="profile_picture"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Picture (Optional)
							</label>
							<input
								type="file"
								className="form-control"
								id="profile_picture"
								accept="image/*"
								onChange={handleFileChange}
							/> */}

							{/* Email */}
							<label
								htmlFor="email"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Email*
							</label>
							<input
								type="email"
								className="form-control"
								value={email}
								onChange={handleInputChange(setEmail)}
								placeholder="Enter Email"
								required
							/>

							{/* Mobile */}
							<label
								htmlFor="mobile"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Mobile*
							</label>
							<input
								type="text"
								className="form-control"
								value={mobile}
								onChange={handleInputChange(setMobile)}
								placeholder="Enter Mobile Number"
								required
							/>

							{/* WhatsApp */}
							<label
								htmlFor="whatsapp"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								WhatsApp
							</label>
							<input
								type="text"
								className="form-control"
								value={whatsapp}
								onChange={handleInputChange(setWhatsapp)}
								placeholder="Enter WhatsApp Number"
							/>

							{/* Topics of Interest */}
							<label
								htmlFor="topics_of_interest"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Topics of Interest
							</label>
							<input
								type="text"
								className="form-control"
								value={topicsOfInterest}
								onChange={handleInputChange(setTopicsOfInterest)}
								placeholder="Enter Topics of Interest"
							/>

							{/* Area of Expertise */}
							<label
								htmlFor="area_of_expertise"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Area of Expertise
							</label>
							<input
								type="text"
								className="form-control"
								value={areaOfExpertise}
								onChange={handleInputChange(setAreaOfExpertise)}
								placeholder="Enter Area of Expertise"
							/>

							{/* Years of Experience */}
							<label
								htmlFor="years_of_experience"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Years of Experience
							</label>
							<input
								type="number"
								className="form-control"
								value={yearsOfExperience}
								onChange={handleInputChange(setYearsOfExperience)}
								placeholder="Enter Years of Experience"
							/>

							{/* Food Safety Standard */}
							<label
								htmlFor="food_safety_standard"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Food Safety Standard Interested
							</label>
							<select
								className="form-control"
								value={foodSafetyStandard}
								onChange={handleInputChange(setFoodSafetyStandard)}>
								<option value="">Select</option>
								<option value="On Farm Production">On Farm Production</option>
								<option value="Post Harvest">Post Harvest</option>
								<option value="Sustainability">Sustainability</option>
								<option value="FSSAI">FSSAI</option>
								<option value="USDA NOP">USDA NOP</option>
								<option value="USFDA">USFDA</option>
								<option value="PCQI">PCQI</option>
								<option value="Others">Others</option>
							</select>

							{/* Preferred Notification */}
							<label
								htmlFor="preferred_notification"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Preferred Notification
							</label>
							<select
								className="form-control"
								value={preferredNotification}
								onChange={handleInputChange(setPreferredNotification)}>
								<option value="">Select</option>
								<option value="WhatsApp">WhatsApp</option>
								<option value="Email">Email</option>
							</select>

							{/* Terms and Privacy */}
							<div className="form-group-inputs">
								<input
									type="checkbox"
									id="terms_and_privacy"
									checked={termsAndPrivacy}
									onChange={handleTermsChange}
									required
									className="me-2"
								/>
								<label
									htmlFor="terms_and_privacy"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									I agree with the Platform terms and privacy policy
								</label>
							</div>

							<div
								className="row"
								style={{
									padding: "15px 15px 15px 15px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									// background: "#F1F1F1",
								}}>
								{/* <div className="col-5"></div> */}
								<div className="col-3">
									<button
										style={{
											borderRadius: "5px",
											padding: "8px 20px",
											color: "#fff",
											background: "#E23F1C",
											border: "none",
											display: "flex",
											alignItems: "center",
											cursor: "pointer",
										}}
										onClick={handleSubmit}>
										{/* <span style={{ marginRight: "8px" }}>+</span> */}
										Join
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
			<Navbar />
			<div className="db-page">
				<div className="container">
					<div className="row">
						<div className="col-xl-12">
							<div className="db-box">
								<div className="db-matter">
									<div className="db-text">
										<span>
											Discussion board serve as a vital tool for enriching the
											online learning experience and promoting a collaborative
											educational environment for the stakeholders in the food
											supply chain.
											<br /> Key goals include:
										</span>
										<ul className="ticked-list">
											<li className="checked-item">
												Facilitating Communication: Provide a space to ask
												questions, share ideas, and discuss course material.
											</li>
											<li className="checked-item">
												Encouraging Collaboration: Promote teamwork through
												group discussions and peer feedback, enhancing the
												learning experience.
											</li>
											<li className="checked-item">
												Building Community: Create a sense of belonging and
												connection among participants, which can improve
												motivation and retention.
											</li>
										</ul>
									</div>
									<div className="db-image">
										<img
											src="../images/Discussion-Board.jpg"
											alt="Discussion Board"
										/>
									</div>
								</div>

								{/* <div className="db-carousel">
									<Carousel
										responsive={responsive}
										infinite={true}
										autoPlay={true}
										autoPlaySpeed={5000}
										transitionDuration={500}
										removeArrowOnDeviceType={["tablet", "mobile"]}>
										<div className="carousel-tab">
											<a
												href="#"
												onClick={() => handleLinkClick(1)}
												className="carousel-link">
												Storage and Awareness
											</a>
										</div>
										<div className="carousel-tab">
											<a
												href="#"
												onClick={() => handleLinkClick(2)}
												className="carousel-link">
												Catering
											</a>
										</div>
										<div className="carousel-tab">
											<a
												href="#"
												onClick={() => handleLinkClick(3)}
												className="carousel-link">
												General-manufacturing-Spices
											</a>
										</div>
										<div className="carousel-tab">
											<a
												href="#"
												onClick={() => handleLinkClick(4)}
												className="carousel-link">
												Milk & milk products
											</a>
										</div>
										<div className="carousel-tab">
											<a
												href="#"
												onClick={() => handleLinkClick(5)}
												className="carousel-link">
												Hazard analysis and Critical Control Points
											</a>
										</div>
										<div className="carousel-tab">
											<a
												href="#"
												onClick={() => handleLinkClick(6)}
												className="carousel-link">
												Organic Certification - NOP
											</a>
										</div>
										<div className="carousel-tab">
											<a
												href="#"
												onClick={() => handleLinkClick(7)}
												className="carousel-link">
												Current Good Manufacturing Practices
											</a>
										</div>
										<div className="carousel-tab">
											<a
												href="#"
												onClick={() => handleLinkClick(8)}
												className="carousel-link">
												Hazard analysis
											</a>
										</div>
										<div className="carousel-tab">
											<a
												href="#"
												onClick={() => handleLinkClick(9)}
												className="carousel-link">
												Risk-based Preventive Controls for Human Food
											</a>
										</div>
									</Carousel>
								</div>

								<div className="db-content">
									<div className="content-display">{renderContent()}</div>
								</div> */}
								<div className="row">
									{groups.length > 0 ? (
										groups.map((e) => {
											return (
												<div
													className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
													style={{ padding: "10px 10px 10px 10px" }}>
													<div class="uc-community-li-main">
														{" "}
														<div class="uc-comm-like-imgs">
															{/* <img
															onerror="communityBannerImgError(this);"
															src="https://www.ulektz.com/assets/img/home/community-common-background.png"
															alt="img"
														/> */}
														</div>
														<div class="uc-comm-userimg">
															{" "}
															<img
																src="/LOGO.PNG"
																alt=""
																onerror="profileImgError(this);"
																class="uc-comm-user-img"
																onclick="communityChangeNew(5421)"
															/>{" "}
														</div>
														{/* <div class="uc-right-top-wrap">
												<a href="javascript:void(0)" class="uc-option-btn">
													{" "}
													<img
														src="https://www.ulektz.com/assets/img/home/options.svg"
														alt="icon"
													/>{" "}
												</a>{" "}
												<a
													href="javascript:void(0)"
													class="uc-rb-btn"
													//   onclick="openReportCommunityPopup(5421,this)"
												>
													<span
														class="uc-rc-pop-span"
														style={{ position: "absolute" }}>
														{/* <img src="https://www.ulektz.com/assets/img/home/Report_post.svg" />{" "}
                                    Report Community *
													</span>
												</a>{" "}
											</div>{" "} */}
														<div class="uc-community-li-con">
															{" "}
															<div>
																<h4 onclick="communityChangeNew(5421)">
																	{e.name}
																</h4>{" "}
																<div class="uc-pro-location">
																	{" "}
																	{/* <p onclick="communityChangeNew(5421)">
															{" "}
															No. 100, Lake View Estate, Kundrathur Main
															Road,Porur, Chennai - 600 116{" "}
														</p>{" "} */}
																	{/* <div
															class="public-text"
															onclick="communityChangeNew(5421)">
															<span class="uc-star-count">
																<a
																	href="javascript:void(0)"
																	class="cmn-btn uc-normal-btn">
																	<img src="https://www.ulektz.com/assets/img/profile/Public_icon.svg" />{" "}
																	Public
																</a>
																<a
																	href="javascript:void(0)"
																	class="cmn-btn uc-blue-btn type">
																	<span class="">Skill</span>
																</a>
															</span>
														</div>{" "} */}
																	<div class="uc-pv-wrap-comb-mem">
																		{" "}
																		<div class="uc-pv-wrap"></div>{" "}
																		<p
																			// onclick="communityChangeNew(5421)"
																			// class="pb-2"
																			style={{ marginBottom: "0rem" }}>
																			<span class="">{e.noofmembers}</span>
																			&nbsp;Members
																		</p>{" "}
																	</div>{" "}
																</div>
															</div>{" "}
															{userRole === "Admin" ? (
																<button
																	className="btn btn-success mt-2"
																	onClick={(event) =>
																		(window.location.href = "/group/" + e.id)
																	}>
																	View
																</button>
															) : (
																<div
																	onClick={() => handleOpenjoinModal(e)}
																	class="cmn-btn uc-join-btn uc-com-join-toggle">
																	+ Join
																</div>
															)}
														</div>{" "}
													</div>
												</div>
											);
										})
									) : (
										<div>
											<h1> No Groups Found</h1>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Discussionboard;
