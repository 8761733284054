import React from "react";
import { Link } from "react-router-dom";
import Adminnavbar from "./adminnavbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
// import "./Menu.css";

const InstructorLed = () => {
	return (
		<>
			<Adminnavbar />

			<Homecarousel />
			<div className="Inled">
				<div className="Inled-box">
					<div className="Inled-container">
						<div className="Inled-content">
							<div className="Inled-content-text">
								<h2 className="Inled-content-heading">Instructor led</h2>
							</div>
							<div className="Inled-content-button">
								<Link to="/schedule-a-class">
									<button className="Inled-content-button-In" type="button">
										Schedule a Class
									</button>
								</Link>
							</div>
							<div className="Inled-content-button">
								<Link to="/attendance">
									<button className="Inled-content-button-In" type="button">
										Attendance
									</button>
								</Link>
							</div>
						</div>
						<div className="Inled-Image">
							<img src="../images/Instructor-Led.png" alt="Image" />
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default InstructorLed;
