import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import Test from "./test";

export default function Examination() {
	// console.log("location", window.location.pathname.split("/")[2]);
	const [data, setData] = useState(null);
	const [viewChange, setViewChange] = useState(true);
	const [startTest, setStartTest] = useState("");
	const [sections, setSections] = useState([]);
	const [started, setStarted] = useState(false);
	const [results, setResults] = useState(null);
	const [score, setScore] = useState(0);

	useEffect(() => {
		getDataAPI(
			`verifyCandidate/${window.location.pathname.split("/")[2]}`
		).then((res) => {
			console.log("res getcoursecategory", res.data);
			if (res.data.courses !== null) {
				setData(res.data.course);
			}
		});
	}, []);

	const handleStart = async () => {
		postDataAPI(
			`participantexamstart`,
			{
				url: window.location.pathname.split("/")[2],
			}
			// auth.token
		).then(async (res) => {
			console.log(res.data);
			if (res.status === 200) {
				setStartTest(true);
				setViewChange("");
				setStarted(true);
			}
			// if (res.status === 200) {
			// 	try {
			// 		const resp = await postDataAPI(
			// 			"examstarted",
			// 			{
			// 				// userid: auth.user.id,
			// 				courseid: data.id,
			// 			}
			// 			// auth.token
			// 		);
			// 		console.log("res", resp);
			// 		if (resp.status === 200) {
			// 			setStartTest(true);
			// 			setViewChange("");
			// 			setStarted(true);
			// 			// window.location.reload();
			// 		}
			// 	} catch (erro) {
			// 		// dispatch({
			// 		// 	type: GLOBALTYPES.ALERT,
			// 		// 	payload: { error: erro.response.data.msg },
			// 		// });
			// 	}
			// } else {
			// 	console.log("res.data.msg", res.data.msg);
			// 	alert(res.data.msg);
			// }
		});
	};

	const handleSubmit = (answers) => {
		setResults(answers);
		setStarted(false);
	};

	const handleScore = async (score) => {
		// alert(score)
		setScore(score);
		// console.log("score", score);
		var percentage = (score / data.questionslist.length) * 100;
		// console.log("percentage", percentage);
		try {
			const res = await postDataAPI(
				"participantexamended",
				{
					// userid: auth.user.id,
					url: window.location.pathname.split("/")[2],
					percentage: percentage,
				}
				// auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				// window.location.reload();
				// if (Number(percentage) > 60) {
				postDataAPI(
					`generatecertificateforparticipant`,
					{
						fullname: data.candidate.Name,
						email: data.candidate.Email,
						coursetitle:
							data.course.coursetitle + " : " + data.course.subcategory.name,
						courseduration: data.course.duration,
						percentage: percentage,
					}
					// auth.token
				).then((res) => {
					console.log("generate certificate ", res.data);
				});
				// }
			}
		} catch (err) {
			// dispatch({
			//   type: GLOBALTYPES.ALERT,
			//   payload: { error: err.response.data.msg },
			// });
		}
	};

	return (
		<>
			{viewChange === true && (
				<div
					style={{
						background: "#fff",
						height: "100vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<div
						className="card"
						style={{
							width: "45vw",
							// height: "40vh",
							paddingBottom: "15px",
						}}>
						<div
							style={{
								background: "#203F54",
								color: "#fff",
								padding: "10px",
								fontSize: "20px",
							}}>
							{data?.coursetitle}
							<br />
							{/* <span style={{ fontSize: "13px" }}>Published by:mahesh</span> */}
						</div>
						{data?.questionslist.length > 0 ? (
							<>
								<div
									style={{
										textAlign: "center",
										fontSize: "23px",
										padding: "10px",
									}}>
									Final Assesment
								</div>
								<div
									className="row"
									style={{ paddingTop: "10px", paddingBottom: "10px" }}>
									<div
										className="col-4"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}>
										{/* <div style={{ color: "red", marginLeft: "-5px" }}>8</div> */}
										{data?.questionslist.length}
										<br />
										Questions
									</div>
									<div
										className="col-4"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<i className="fa fa-sharp fa-solid fa-arrow-trend-up"></i>
										{data?.questionslist.length *
											parseInt(data?.questionsduration)}
										<br />
										Minutes
									</div>
									<div
										className="col-4"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<i
											className="fa fa-regular fa-clock fa-3x"
											style={{ color: "#ebb813", paddingRight: "4px" }}></i>
										{data?.questionslist.length}
										<br />
										Marks
									</div>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<Button
										style={{
											background: "#20ce8f",
											border: "none",
											borderRadius: "4px",
										}}
										onClick={() => {
											handleStart();
										}}>
										Start test
									</Button>
								</div>
							</>
						) : (
							<>
								<div
									style={{
										textAlign: "center",
										fontSize: "23px",
										padding: "10px",
									}}>
									No Assessment for this course
								</div>
							</>
						)}
					</div>
				</div>
			)}
			{startTest === true && (
				<>
					<div
						style={{
							background: "#fff",
							height: "100vh",
							padding: "10px",
						}}>
						<div
							style={{
								background: "#203F54",
								color: "#fff",
								padding: "10px",
								fontSize: "20px",
								borderRadius: "5px",
								marginBottom: "10px",
							}}>
							{data.coursetitle}
							<br />
							{/* <span style={{ fontSize: "13px" }}>Published by:mahesh</span> */}
						</div>
						<div
							style={{
								background: "lightgrey",
								color: "#fff",
								padding: "20px",
								fontSize: "20px",
								borderRadius: "5px",
							}}>
							Final Assesment
						</div>
						{/* <div>
                  {data &&
                    data.questionslist.map((questionData, index) => (
                      <Question
                        key={index}
                        question={questionData.question}
                        options={questionData.options}
                        selectedOption={selectedOptions[index]}
                        handleOptionChange={(selectedOption) =>
                          handleOptionChange(index, selectedOption)
                        }
                      />
                    ))}
                </div> */}
						<div>
							{results ? (
								<div style={{ textAlign: "center", marginTop: "50px" }}>
									<h2>Test Results</h2>
									{/* <pre>{JSON.stringify(results, null, 2)}</pre> */}
									{/* <div>{results}</div> */}
									<p>
										Your score is {score} out of {data.questionslist.length}
									</p>
								</div>
							) : started ? (
								<Test
									onSubmit={handleSubmit}
									questions={data.questionslist}
									questionsduration={data.questionsduration}
									scoretemp={handleScore}
								/>
							) : (
								// <StartTest onStart={handleStart} />
								<></>
							)}
						</div>
					</div>
				</>
			)}
		</>
	);
}
