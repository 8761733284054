import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import {
	BulkCheckboxControl,
	DatatableWrapper,
	EmptyTablePlaceholder,
	Filter,
	Pagination,
	PaginationOptions,
	TableBody,
	TableHeader,
	TableRow,
} from "react-bs-datatable";

import { parse } from "@fortawesome/fontawesome-svg-core";
import "bootstrap/dist/css/bootstrap.min.css";

// Then, use it in a component.
function DataTable(props) {
	var grandtotal,
		grandmale,
		grandfemale = 0;

	console.log("grandtotal", grandtotal);
	return (
		// <DatatableWrapper
		// 	body={props.body}
		// 	headers={props.headers}
		// 	sortProps={{
		// 		sortValueObj: {
		// 			date: (date) =>
		// 				parse(`${date}`, "MMMM dd, yyyy", new Date()).getTime(),
		// 		},
		// 	}}
		// 	paginationOptionsProps={{
		// 		initialState: {
		// 			rowsPerPage: 10,
		// 			options: [5, 10, 15, 20],
		// 		},
		// 	}}>
		// 	<Row className="mb-4">
		// 		<Col
		// 			xs={12}
		// 			lg={4}
		// 			className="d-flex flex-col justify-content-end align-items-end">
		// 			<Filter />
		// 		</Col>
		// 		<Col
		// 			xs={12}
		// 			sm={6}
		// 			lg={4}
		// 			className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
		// 			<PaginationOptions alwaysShowPagination />
		// 		</Col>
		// 		<Col
		// 			xs={12}
		// 			sm={6}
		// 			lg={4}
		// 			className="d-flex flex-col justify-content-end align-items-end">
		// 			<Pagination alwaysShowPagination paginationRange={3} />
		// 		</Col>
		// 		<Col xs={12} className="mt-2">
		// 			<BulkCheckboxControl />
		// 		</Col>
		// 	</Row>
		// 	<Table>
		// 		<TableHeader />
		// 		<TableBody />
		// 	</Table>
		// </DatatableWrapper>
		<DatatableWrapper
			body={props.body}
			headers={props.headers}
			sortProps={{
				sortValueObj: {
					date: (date) => {
						parse(`${date}`, "DD/MM/YY", new Date()).getTime();
					},
				},
			}}
			paginationOptionsProps={{
				initialState: {
					rowsPerPage: 10,
					options: [5, 10, 15, 20, 30],
				},
			}}>
			<Row className="mb-4">
				<Col
					xs={12}
					lg={4}
					className="d-flex flex-col justify-content-end align-items-end">
					<Filter />
				</Col>
				<Col
					xs={12}
					sm={6}
					lg={4}
					className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
					<PaginationOptions alwaysShowPagination />
				</Col>
				<Col
					xs={12}
					sm={6}
					lg={4}
					className="d-flex flex-col justify-content-end align-items-end">
					<Pagination alwaysShowPagination paginationRange={3} />
				</Col>
				<Col xs={12} className="mt-2">
					<BulkCheckboxControl />
				</Col>
			</Row>
			<Table bordered striped>
				<TableHeader />
				<TableBody>
					{(rows) =>
						rows.length === 0 ? (
							<EmptyTablePlaceholder />
						) : (
							<>
								{rows.map((rowData, rowIdx) => {
									if (rowIdx === 0) {
										console.log("index", rowIdx);
										grandtotal = 0;
										grandfemale = 0;
										grandmale = 0;
									}
									grandtotal = grandtotal + Number(rowData.total);
									grandfemale = grandfemale + Number(rowData.female);
									grandmale = grandmale + Number(rowData.male);

									return rowData.status === "unknown" ? (
										<tr key={rowData.username}>
											<td colSpan={props.headers.length}>Row status unknown</td>
										</tr>
									) : (
										<>
											<TableRow
												key={rowData.username}
												rowData={rowData}
												rowIdx={rowIdx}
												// onRowClick={(row) =>
												// 	alert(`Clicked row containing name ${row.name}.`)
												// }
											/>
										</>
									);
								})}
								{/* <tr>
									<td colSpan={8}></td>
									<td>{grandmale}</td>
									<td>{grandfemale}</td>
									<td>{grandtotal}</td>
								</tr> */}
							</>
						)
					}
				</TableBody>
			</Table>
		</DatatableWrapper>
	);
}

export default DataTable;
