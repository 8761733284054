import React from "react";
// import '../Menu2.css';
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const ProblemStatement = () => {
	return (
		<>
			<Navbar />
			<div className="" style={{}}>
				<Homecarousel />
			</div>
			<div
				className="problem-statement-container fadeInUp"
				style={{ backgroundImage: "url('../images/shape2.svg')" }}>
				<div className="inner-container">
					<div className="heading-container">
						<h2 className="heading-title">Problem Statement</h2>
					</div>
					<div className="description-container">
						<p className="description-text">
							Many developing countries, including India, face significant
							challenges in adopting and complying with global food safety and
							sustainability standards. Key obstacles include:
						</p>
					</div>
					<div className="content-container">
						<div className="content-container-1">
							<div className="content-item">
								<h4 className="content-title">
									Gaps in Awareness and Training:
								</h4>
								<p className="content-description">
									Limited access to training resources makes it difficult to
									implement standards across the food supply chain.
								</p>
							</div>
							<div className="content-item">
								<h4 className="content-title">Shortage of Skilled Trainers:</h4>
								<p className="content-description">
									The absence of qualified trainers in crop production centers
									hinders the transfer of knowledge and the adoption of best
									practices.
								</p>
							</div>
						</div>
						<div className="content-container-2">
							<div className="content-item">
								<h4 className="content-title">
									Complexity in Navigating Compliance:
								</h4>
								<p className="content-description">
									Farmers and medium-scale processors often struggle to
									interpret and apply international standards effectively.
								</p>
							</div>
							<div className="content-item">
								<h4 className="content-title">
									Lack of Implementation Support:
								</h4>
								<p className="content-description">
									Accredited certification bodies do not provide implementation
									assistance due to conflict of interest.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default ProblemStatement;
