import {
	faKey,
	faPaperPlane,
	faPhone,
	faSuitcase,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import "../Menu.css";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { postDataAPI } from "../utils/fetchData";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Employer = () => {
	const dropdownRef = useRef(null);
	const dropdownRef1 = useRef(null);
	const dropdownRef2 = useRef(null);
	const dropdownRef3 = useRef(null);
	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenFSSI, setIsOpenFSSI] = useState(false);
	const [isOpenTypes, setIsOpenTypes] = useState(false);
	const [isOpenLevels, setIsOpenLevels] = useState(false);
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [selectedOption, setSelectedOption] = useState(
		"Select area of expertise"
	);
	const [selectedFSSIOption, setSelectedFSSIOption] = useState(
		"Select Food Safety Standard Interested"
	);
	const [selectedTypesOption, setSelectedTypesOption] = useState(
		"Select Employment type"
	);
	const [selectedLevelOption, setSelectedLevelOption] = useState(
		"Select Experience Level"
	);
	const initialState = {
		employername: "",
		website: "",
		industry: "",
		contactinformation: "",
		primaryjobtitle: "",
		email: "",
		password: "",
		mobile: "",
		jobtitle: "",
		joblocation: "",
		orgareaofexpertise: "",
		hireareaofexpertise: "",
		FSSI: "",
		employmenttype: "",
		experiencelevel: "",
	};
	const [userData, setUserData] = useState(initialState);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const toggleDropdownFSSI = () => {
		setIsOpenFSSI(!isOpenFSSI);
	};

	const toggleDropdownType = () => {
		setIsOpenTypes(!isOpenTypes);
	};

	const toggleDropdownLevel = () => {
		setIsOpenLevels(!isOpenLevels);
	};

	const handleOptionClick = (option) => {
		setSelectedOption(option);
		setUserData({ ...userData, orgareaofexpertise: option });
		setIsOpen(false);
	};

	const handleOptionFSSIClick = (option) => {
		setSelectedFSSIOption(option);
		setUserData({ ...userData, FSSI: option });
		setIsOpenFSSI(false);
	};

	const handleOptionTypeClick = (option) => {
		setSelectedTypesOption(option);
		setUserData({ ...userData, employmenttype: option });
		setIsOpenTypes(false);
	};

	const handleOptionLevelClick = (option) => {
		setSelectedLevelOption(option);
		setUserData({ ...userData, experiencelevel: option });
		setIsOpenLevels(false);
	};

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};
	const handlePasswordChange = (e) => {
		const value = e.target.value;
		setPassword(value);
		setUserData({ ...userData, password: value });
	};
	const handleRegister = async () => {
		console.log("userdata", userData);
		try {
			const res = await postDataAPI("createemployer", {
				...userData,
			});
			console.log("res", res);
			if (res.status === 200) {
				alert("Thank you for registering");
				window.location.href = "./employerlogin";
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="emp">
				<div className="emp-box">
					<div className="emp-heading">
						<h2>Employer</h2>
					</div>
					<div className="emp-boxIn">
						<div className="emp-greenbox">
							<div>
								<h4 className="emp-greenbox-heading">
									1. Organization information
								</h4>
							</div>
							<div>
								{/* <form> */}
								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Employer Name"
											name="employername"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faUser} />
									</div>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Employer Website"
											name="website"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faPaperPlane} />
									</div>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Industry"
											name="industry"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faPhone} />
									</div>
								</div>

								<div className="js-inputbox">
									<span className="js-inputbox-input">
										<div className="js-dropdown-container" ref={dropdownRef}>
											<div className="js-dropdown">
												<div
													className="js-dropdown-header"
													onClick={toggleDropdown}>
													{selectedOption}
													<span
														className={`js-dropdown-symbol ${
															isOpen ? "open" : ""
														}`}
													/>
												</div>

												{isOpen && (
													<ul className="js-dropdown-list">
														{[
															"Select Company Size",
															"1 - 50 Employees",
															"51 - 200 Employees",
															"201 - 500 Employees",
															"500+ Employees",
														].map((option, index) => (
															<li
																key={index}
																className="js-dropdown-item"
																onClick={() => handleOptionClick(option)}>
																{option}
															</li>
														))}
													</ul>
												)}
											</div>
										</div>
									</span>
									<div className="js-Icon">
										<FontAwesomeIcon icon={faSuitcase} />
									</div>
								</div>

								<div>
									<h4 className="emp-greenbox-heading">
										2. Primary Contact Information
									</h4>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											onChange={handleChangeInput}
											type="text"
											placeholder="Contact Information"
											name="contactinformation"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faUser} />
									</div>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Job Title"
											name="primaryjobtitle"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faKey} />
									</div>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Email"
											name="email"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faKey} />
									</div>
								</div>
								<div className="emp-inputbox">
									<span className="js-inputbox-input">
										<input
											placeholder="Password"
											type={showPassword ? "text" : "password"}
											onChange={handlePasswordChange}
										/>
									</span>
									<div
										className="toggle-password d-flex align-items-center emp-Icon"
										onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? (
											<>
												<FaEyeSlash className="me-1" />
											</>
										) : (
											<>
												<FaEye className="me-1" />
											</>
										)}
									</div>
								</div>
								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											placeholder="Mobile Number"
											onChange={handleChangeInput}
											name="mobile"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faKey} />
									</div>
								</div>

								<div>
									<h4 className="emp-greenbox-heading">
										3. Hiring Preferences
									</h4>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Job Title"
											name="jobtitle"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faUser} />
									</div>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											onChange={handleChangeInput}
											type="text"
											placeholder="Job Location Preference"
											name="joblocation"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faKey} />
									</div>
								</div>

								<div className="js-inputbox">
									<span className="js-inputbox-input">
										<div className="js-dropdown-container" ref={dropdownRef1}>
											<div className="js-dropdown">
												<div
													className="js-dropdown-header"
													onClick={toggleDropdownFSSI}>
													{selectedFSSIOption}
													<span
														className={`js-dropdown-symbol ${
															isOpenFSSI ? "open" : ""
														}`}
													/>
												</div>

												{isOpenFSSI && (
													<ul className="js-dropdown-list">
														{[
															"Select Food Safety Standard Interested",
															"On-Farm Production",
															"Post Harvest",
															"Sustainability",
															"FSSAI",
															"USDA NOP",
															"USFDA PCQI",
														].map((option, index) => (
															<li
																key={index}
																className="js-dropdown-item"
																onClick={() => handleOptionFSSIClick(option)}>
																{option}
															</li>
														))}
													</ul>
												)}
											</div>
										</div>
									</span>
									<div className="js-Icon">
										<FontAwesomeIcon icon={faSuitcase} />
									</div>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Area of Expertise"
											name="hireareaofexpertise"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faKey} />
									</div>
								</div>

								<div className="js-inputbox">
									<span className="js-inputbox-input">
										<div className="js-dropdown-container" ref={dropdownRef2}>
											<div className="js-dropdown">
												<div
													className="js-dropdown-header"
													onClick={toggleDropdownType}>
													{selectedTypesOption}
													<span
														className={`js-dropdown-symbol ${
															isOpenTypes ? "open" : ""
														}`}
													/>
												</div>

												{isOpenTypes && (
													<ul className="js-dropdown-list">
														{[
															"Select Food Safety Standard Interested",
															"Full Time",
															"Part Time",
															"Freelance",
															"Internship",
														].map((option, index) => (
															<li
																key={index}
																className="js-dropdown-item"
																onClick={() => handleOptionTypeClick(option)}>
																{option}
															</li>
														))}
													</ul>
												)}
											</div>
										</div>
									</span>
									<div className="js-Icon">
										<FontAwesomeIcon icon={faSuitcase} />
									</div>
								</div>

								<div className="js-inputbox">
									<span className="js-inputbox-input">
										<div className="js-dropdown-container" ref={dropdownRef3}>
											<div className="js-dropdown">
												<div
													className="js-dropdown-header"
													onClick={toggleDropdownLevel}>
													{selectedLevelOption}
													<span
														className={`js-dropdown-symbol ${
															isOpenLevels ? "open" : ""
														}`}
													/>
												</div>

												{isOpenLevels && (
													<ul className="js-dropdown-list">
														{[
															"Select Experience Level",
															"Entry Level",
															"Mid-Level",
															"Senior Executive",
														].map((option, index) => (
															<li
																key={index}
																className="js-dropdown-item"
																onClick={() => handleOptionLevelClick(option)}>
																{option}
															</li>
														))}
													</ul>
												)}
											</div>
										</div>
									</span>
									<div className="js-Icon">
										<FontAwesomeIcon icon={faSuitcase} />
									</div>
								</div>

								<button onClick={handleRegister} className="emp-submitbtn">
									SUBMIT
								</button>
								{/* </form> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Employer;
